import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const isNight = () => {
  const date = new Date();
  return (
    (Cookies.get('nightMode') && Cookies.get('nightMode') === 'true') ||
    date.getHours() >= 16 ||
    date.getHours() < 6
  );
};

const initialState = {
  isNightMode: isNight(),
};

const nightModeSlice = createSlice({
  name: 'nightMode',
  initialState,
  reducers: {
    setNightMode(state, action) {
      state.isNightMode = action.payload;
      if (!action.payload) {
        Cookies.set('nightMode', 'false');
      } else {
        Cookies.set('nightMode', 'true');
      }
    },
  },
});

export const { setNightMode } = nightModeSlice.actions;

export default nightModeSlice.reducer;
