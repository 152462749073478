import {
  AsyncThunkAction,
  AsyncThunkPayloadCreatorReturnValue,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import Agent from '../../interfaces/agent';
import { Tag } from '../../interfaces/tag';

export interface CreateTagProps {
  name: string;
  category: string;
  supported_contact_types: string[];
}

export interface UpdateTagProps {
  id: number;
  name: string;
  category: string;
  supported_contact_types: string[];
}

// Refresh Agent if tags refreshes
export const refreshAgent = createAsyncThunk<Agent, number>(
  'tags/refreshAgent',
  async (agentId) => {
    const response = await instance.get(`/deals/agent/${agentId}/`);
    return response.data;
  },
);

// Create Tag

export const createTag = createAsyncThunk<Tag, CreateTagProps>(
  'tags/createTag',
  async (tagProps, { rejectWithValue }) => {
    try {
      const response = await instance.post(`/contacts/tag/`, {
        ...tagProps,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  },
);

export const getAllTags = createAsyncThunk<Tag[]>(
  'tags/getAllTags',
  async (__empty, { rejectWithValue }) => {
    try {
      const response = await instance.get(`/contacts/tags/`);
      if (!response.data) {
        return rejectWithValue(true);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  },
);

export const deleteTag = createAsyncThunk<any, number>(
  'tags/deleteTag',
  async (id, { rejectWithValue }) => {
    try {
      const response = await instance.delete(`/contacts/tag/${id}/`);
      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  },
);

export const updateTag = createAsyncThunk<Tag, UpdateTagProps>(
  'tags/updateTag',
  async (updateProps, { rejectWithValue }) => {
    try {
      const response = await instance.patch(
        `/contacts/tag/${updateProps.id}/`,
        {
          name: updateProps.name,
          category: updateProps.category,
          supported_contact_types: updateProps.supported_contact_types,
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  },
);

export const getTagById = createAsyncThunk<Tag, number>(
  'tags/getTagById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await instance.get(`/contacts/tag/${id}/`);
      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  },
);
