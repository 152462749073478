import Cookies from 'js-cookie';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import { signOutHandler } from '../../utils/signOutHandler';
import { baseURL } from '../../config/constant';

const getBearerToken = async (): Promise<string> => {
  const session = await Auth.currentSession();
  const idTokenExpire = session.getAccessToken().getExpiration();
  const refreshToken = session.getRefreshToken();
  const currentTimeSeconds = Math.round(+new Date() / 1000);
  if (idTokenExpire < currentTimeSeconds) {
    const user = await Auth.currentAuthenticatedUser();
    return user.refreshSession(
      refreshToken,
      (
        err: any,
        data: {
          getAccessToken: () => {
            (): any;
            new (): any;
            getJwtToken: { (): any; new (): any };
          };
        },
      ) => {
        if (err) {
          Sentry.captureException(err);
          return Auth.signOut()
            .then(() => {
              signOutHandler();
              return null;
            })
            .catch((e) => {
              Sentry.captureException(e);
              return null;
            });
        }
        return Promise.resolve(data.getAccessToken().getJwtToken());
      },
    );
  }
  return session.getAccessToken().getJwtToken();
};

type JWTToken = {
  exp: number;
  user_id: number;
};

const refreshAuthTokensIfNeeded = async (refresh: string): Promise<number> => {
  // if token is expired we use the refresh token to get a new token
  try {
    // Refresh token
    const response = await axios.post(`${baseURL}/contacts/token/refresh/`, {
      refresh,
    });

    const newAccess = response.data.access;
    const newRefresh = response.data.refresh;
    const decodedAccess = jwtDecode<JWTToken>(newAccess);
    const decodedRefresh = jwtDecode<JWTToken>(newRefresh);
    Cookies.set('access', newAccess, {
      expires: new Date(decodedAccess.exp * 1000),
    });
    Cookies.set('refresh', newRefresh, {
      expires: new Date(decodedRefresh.exp * 1000),
    });
    return decodedAccess.user_id;
  } catch (e) {
    // Throw the error
    throw new Error('Refresh Failed');
  }
};

const setLastUserId = (access) => {
  try {
    const decodedToken = jwtDecode<JWTToken>(access);
    Cookies.set('lastUserId', decodedToken.user_id.toString());
  } catch (e) {
    console.error('Problem decoding token');
    Sentry.captureException(e);
  }
};

const attemptRefresh = async (config, refresh) => {
  const access = Cookies.get('access');
  if (access) {
    try {
      const decodedToken = jwtDecode<JWTToken>(access);
      // Check if token has expired
      if (decodedToken.exp * 1000 < Date.now()) {
        Cookies.remove('access');
        await refreshAuthTokensIfNeeded(refresh);
        // Was refreshed
        const newToken = Cookies.get('access');
        setLastUserId(newToken);
        config.headers.Authorization = `Bearer ${newToken}`;
        return config;
      }
      setLastUserId(access);
      config.headers.Authorization = `Bearer ${access}`;
      return config;
    } catch (e) {
      console.error('Error removing token');
      throw new Error('Failed decoding tokens');
    }
  }
  try {
    await refreshAuthTokensIfNeeded(refresh);
    // Was refreshed
    const newToken = Cookies.get('access');
    config.headers.Authorization = `Bearer ${newToken}`;
    return config;
  } catch (e) {
    throw new Error('Refresh Failed');
  }
};

const authService = {
  getBearerToken,
  setLastUserId,
  refreshAuthTokensIfNeeded,
  attemptRefresh,
};

export default authService;
