import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import { Stats } from '../../interfaces/stats';

export interface AgentAreaStatsRequestParams {
  polygon: any;
  timeframe: number;
  referral_type: string;
  commission_model_id?: number;
}

export const fetchAgentAreaStats = createAsyncThunk<
  Stats,
  AgentAreaStatsRequestParams
>('agentAreaStats/fetchAgentAreaStats', async (body) => {
  const response = await instance.post(`/deals/agent-area-stats/`, body);
  return response.data;
});
