import { createSlice } from '@reduxjs/toolkit';
import { fetchSearchById, patchSearchById } from '../searches/actions';
import { createOltRecord } from './actions';
import {
  OutboundLiveTransferInvitation,
  OutboundLiveTransferRecord,
} from '../../interfaces/search';
import {
  makeOltInvitationToOltRecordMap,
  sortOltInvitations,
} from '../../utils/helpers';

export enum ModalPage {
  PickupPage = 1,
  CompletedTransferPage = 2,
  IncompleteTransferReasonPage = 3,
  OtherITRPage = 4,
  SubmissionPage = 5,
}

export enum IncompleteTransferReasons {
  REJECTED = 'REJECTED',
  UNPREPARED = 'UNPREPARED',
  OTHER = 'OTHER',
}

type OLTRecordPayload = {
  agent_pickup: boolean;
  transfer_complete: boolean;
  incomplete_transfer_reason: IncompleteTransferReasons | null;
  notes: string;
};

type OltInvitationToOltRecordMap = {
  [oltInvitationId: number]: OutboundLiveTransferRecord;
};

type OutboundLiveTransferState = {
  // TODO: type this better
  olt_invitations: any[];
  oltInvitationToOltRecordMap: OltInvitationToOltRecordMap;
  modalPage: ModalPage | null;
  oltRecordPayload: OLTRecordPayload | null;
  outbound_live_transfer_invitation: OutboundLiveTransferInvitation | null;
  error: any | null;
  successfullyCreatedRecord: boolean;
};

const defaultOltRecordPayload: OLTRecordPayload = {
  agent_pickup: false,
  transfer_complete: false,
  incomplete_transfer_reason: null,
  notes: '',
};

const initialState: OutboundLiveTransferState = {
  olt_invitations: [],
  oltInvitationToOltRecordMap: {},
  modalPage: null,
  oltRecordPayload: defaultOltRecordPayload,
  outbound_live_transfer_invitation: null,
  error: null,
  successfullyCreatedRecord: false,
};

const outboundLiveTransferSlice = createSlice({
  name: 'outboundLiveTransfer',
  initialState,
  reducers: {
    saveModalState(state, action) {
      const { modalPage, oltRecordPayload, invitation } = action.payload;
      state.modalPage = modalPage;
      state.oltRecordPayload = {
        ...state.oltRecordPayload,
        ...oltRecordPayload,
      };
      if (invitation !== undefined) {
        state.outbound_live_transfer_invitation = invitation;
      }
    },
    resetOltModalState(state) {
      state.modalPage = null;
      state.oltRecordPayload = defaultOltRecordPayload;
      state.outbound_live_transfer_invitation = null;
      state.error = null;
      state.successfullyCreatedRecord = false;
    },
    liveUpdateOltInvitation(state, action) {
      const oltInvitation = action.payload;
      state.olt_invitations = state.olt_invitations
        .map((olt_invitation) => {
          if (olt_invitation.id === oltInvitation.id) {
            return oltInvitation;
          }
          return olt_invitation;
        })
        .sort(sortOltInvitations);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchById.fulfilled, (state, action) => {
      const { olt_invitations, olt_records } = action.payload;
      state.olt_invitations = [...olt_invitations].sort(sortOltInvitations);
      state.oltInvitationToOltRecordMap =
        makeOltInvitationToOltRecordMap(olt_records);
    });
    builder.addCase(patchSearchById.fulfilled, (state, action) => {
      const { olt_invitations, olt_records } = action.payload;
      state.olt_invitations = [...olt_invitations].sort(sortOltInvitations);
      state.oltInvitationToOltRecordMap =
        makeOltInvitationToOltRecordMap(olt_records);
    });
    builder.addCase(createOltRecord.fulfilled, (state, action) => {
      state.oltInvitationToOltRecordMap = {
        ...state.oltInvitationToOltRecordMap,
        [action.payload.outbound_live_transfer_invitation]: action.payload,
      };
      state.oltRecordPayload = defaultOltRecordPayload;
      state.successfullyCreatedRecord = true;
    });
    builder.addCase(createOltRecord.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { liveUpdateOltInvitation, resetOltModalState, saveModalState } =
  outboundLiveTransferSlice.actions;

export default outboundLiveTransferSlice.reducer;
