import { createSlice } from '@reduxjs/toolkit';
import {
  ErrorResponse,
  SLA,
  SLAProps,
  createSLA,
  deleteSLA,
  fetchSLARequests,
  patchSLA,
} from './actions';

interface SLAState {
  loading: boolean;
  hasErrors: boolean;
  errors: any;
  slas: SLAProps | null;
  currentPage: number;
  selectedSLA: SLA | null;
  successfullyUpdatedSLA: boolean;
  successfullyDeletedSLA: boolean;
}

const initialState: SLAState = {
  loading: false,
  hasErrors: false,
  errors: null,
  slas: null,
  currentPage: 1,
  selectedSLA: null,
  successfullyUpdatedSLA: false,
  successfullyDeletedSLA: false,
};

const slasSlice = createSlice({
  name: 'slas',
  initialState,
  reducers: {
    selectSLA(state, { payload }) {
      state.selectedSLA = payload;
      // set default success state flag to false
      state.successfullyUpdatedSLA = false;
      state.errors = null;
      state.hasErrors = false;
    },
    resetSLAState(state) {
      state.successfullyDeletedSLA = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSLARequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSLARequests.fulfilled, (state, { meta, payload }) => {
      state.loading = false;
      if ((payload as ErrorResponse).hasError) {
        state.hasErrors = true;
        state.errors = (payload as ErrorResponse).error;
      } else {
        state.slas = payload as SLAProps;
        state.currentPage = meta.arg.page;
      }
    });
    builder.addCase(fetchSLARequests.rejected, (state) => {
      state.hasErrors = true;
    });
    builder.addCase(createSLA.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSLA.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as ErrorResponse).hasError) {
        state.hasErrors = true;
        state.errors = (payload as ErrorResponse).error;
      } else {
        state.selectedSLA = payload as SLA;
        state.successfullyUpdatedSLA = true;
      }
    });
    builder.addCase(createSLA.rejected, (state) => {
      state.hasErrors = true;
    });
    builder.addCase(patchSLA.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchSLA.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as ErrorResponse).hasError) {
        state.hasErrors = true;
        state.errors = (payload as ErrorResponse).error;
      } else {
        const updatedSla = payload as SLA;
        state.selectedSLA = updatedSla;
        state.slas = {
          ...state.slas,
          results: state.slas!.results.map((sla) =>
            updatedSla.id === sla.id ? updatedSla : sla,
          ),
        } as SLAProps;
      }
    });
    builder.addCase(patchSLA.rejected, (state) => {
      state.hasErrors = true;
    });
    builder.addCase(deleteSLA.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSLA.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as ErrorResponse).hasError) {
        state.hasErrors = true;
        state.errors = (payload as ErrorResponse).error;
      } else {
        state.successfullyDeletedSLA = true;
      }
    });
    builder.addCase(deleteSLA.rejected, (state) => {
      state.hasErrors = true;
    });
  },
});

export const { selectSLA, resetSLAState } = slasSlice.actions;

export default slasSlice.reducer;
