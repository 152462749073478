import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { InvitationRequestParams } from '../invitations/actions';
import { handleErrorObject } from '../../utils/errors/handleErrorObject';
import { ErrorResource } from '../../types/error';
import instance from '../../axiosInstance';

export const postDirectMatch = createAsyncThunk<any, InvitationRequestParams>(
  'directMatches/postDirectMatch',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const response = await instance.post('/deals/direct-match/', props);
      return response.data;
    } catch (_err) {
      const error = _err as AxiosError;
      handleErrorObject({
        error,
        dispatch,
        errorResource: ErrorResource.DIRECT_MATCH_POST,
      });
      return rejectWithValue(error?.response?.data);
    }
  },
);
