import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import Invitation from '../../interfaces/invitation';

export interface InvitationRequestParams {
  agent_id: string | number;
  search_id: string | number;
  delay_time: number;
  sms_intro_message: string;
  email_intro_message: string;
  custom_agent_commission?: number | null;
  sourcing_type?: string | null;
  playbook_id?: string | number | null;
  supress_communications?: boolean;
}
export const postInvitation = createAsyncThunk<
  Invitation | any,
  InvitationRequestParams
>('invitations/postInvitation', async (properties) => {
  try {
    const response = await instance.post(`/deals/invitation/`, {
      agent: properties.agent_id,
      search: properties.search_id,
      delay_time: properties.delay_time,
      sms_intro_message: properties.sms_intro_message,
      email_intro_message: properties.email_intro_message,
      custom_agent_commission: properties.custom_agent_commission,
      sourcing_type: properties.sourcing_type,
      playbook_id: properties.playbook_id || null,
      supress_communications: properties.supress_communications || false,
    });
    return response.data;
  } catch (err) {
    const typedErr: any = err;
    return { error: true, data: typedErr.response.data };
  }
});

export const cancelInvitationById = createAsyncThunk<Invitation, number>(
  'invitations/cancelInvitation',
  async (id) => {
    const response = await instance.get(`/deals/invitation/${id}/cancel/`);
    return response.data;
  },
);
