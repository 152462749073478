export function isNotNullOrUndefined<T>(
  value: T | null | undefined,
): value is T {
  const isNotNull = value !== null && value !== 'null';
  const isNotUndefined = value !== undefined && value !== 'undefined';
  return isNotNull && isNotUndefined;
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
