import { createSlice } from '@reduxjs/toolkit';
import { createOltRecord } from '../outboundLiveTransfer/actions';

interface ModalState {
  open: boolean;
  component: {
    type: any;
    props: any;
  };
}

const initialState: ModalState = {
  open: false,
  component: {
    type: null,
    props: {},
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalOpenState(state, action) {
      state.open = action.payload;
    },
    setModalComponent(state, action) {
      state.component = action.payload;
    },
    closeModal(state) {
      state.open = false;
      state.component = {} as any;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOltRecord.fulfilled, (state) => {
      state.open = false;
      state.component = {} as any;
    });
  },
});

export const { setModalOpenState, setModalComponent, closeModal } =
  modalSlice.actions;

// Reducer
export default modalSlice.reducer;
