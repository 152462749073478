import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import instance from '../../axiosInstance';
import {
  CommissionModelOption,
  RemotePresetFilters,
  PresetFilters,
  TagOption,
} from '../../types/searchFilters';
import { FilterTypes } from '../../enums/FilterTypes';
import { updateFilters } from './reducer';
import { commissionModelLabelById } from '../../enums/commission_models';
import { resetNearbyAgents } from '../map/reducer';

function searchFilterAdapter(remoteData: RemotePresetFilters): PresetFilters {
  const commissionModels: CommissionModelOption[] =
    remoteData.commission_models.map((cm) => ({
      label: commissionModelLabelById[cm.id] ?? cm.id,
      value: cm.id,
    }));
  const tags: TagOption[] = remoteData.tags.map((tag) => ({
    label: tag.name,
    value: tag.name,
    category: tag.category,
  }));

  return { commissionModels, tags };
}

export const getSearchFilters = createAsyncThunk<PresetFilters, number>(
  'searchFilters/getSearchFilters',
  async (searchId, { dispatch, rejectWithValue }) => {
    try {
      const response = await instance.get<RemotePresetFilters>(
        `/deals/search/${searchId}/amp-filters/`,
      );
      const data = searchFilterAdapter(response.data);

      let haveFiltersBeenUpdated = false;
      if (data.commissionModels.length) {
        dispatch(
          updateFilters({
            filterType: FilterTypes.selectedCommissionModels,
            value: data.commissionModels,
          }),
        );
        haveFiltersBeenUpdated = true;
      }
      if (data.tags.length) {
        dispatch(
          updateFilters({
            filterType: FilterTypes.tags,
            value: data.tags,
          }),
        );
        haveFiltersBeenUpdated = true;
      }
      if (haveFiltersBeenUpdated) dispatch(resetNearbyAgents());

      return data;
    } catch (err) {
      if (isAxiosError(err)) {
        return rejectWithValue(err.message);
      }
      throw err;
    }
  },
);
