import { createSlice } from '@reduxjs/toolkit';
import { getTemplates } from './actions';

interface TemplateState {
  hasErrors: boolean;
  loading: boolean;
  templates: any;
}

const initialState: TemplateState = {
  hasErrors: false,
  loading: false,
  templates: null,
};

const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTemplates.pending, (state) => {
      state.loading = true;
      state.hasErrors = false;
    });
    builder.addCase(getTemplates.fulfilled, (state, { payload }) => {
      state.templates = { ...payload };
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(getTemplates.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
  },
});

export default templateSlice.reducer;
