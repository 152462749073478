import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import User from '../../interfaces/user';

export const getUser = createAsyncThunk<User>(
  'users/getUser',
  async (_, { rejectWithValue }) => {
    try {
      const me = await instance.get('/contacts/me/');
      const userId = me.data.user_id;
      const response = await instance.get(`contacts/user/${userId}/`);

      return response.data;
    } catch (err) {
      const typedErr: any = err;
      return rejectWithValue(typedErr.response.data);
    }
  },
);
