import { createSlice } from '@reduxjs/toolkit';
import { fetchPastSaleById } from './actions';
import { PastSale } from '../../interfaces/pastSale';

interface PastSalesState {
  hasErrors: boolean;
  loading: boolean;
  pastSalesMap: {
    [key: string]: PastSale;
  };
}

const initialState: PastSalesState = {
  hasErrors: false,
  loading: false,
  pastSalesMap: {},
};

const pastSalesSlice = createSlice({
  name: 'pastSales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPastSaleById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPastSaleById.fulfilled, (state, { meta, payload }) => {
      state.pastSalesMap[meta.arg] = { ...payload, id: meta.arg };
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchPastSaleById.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true; // TODO: add errors
    });
  },
});

// Reducer
export default pastSalesSlice.reducer;
