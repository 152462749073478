import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import * as MixpanelUtils from './utils/mixpanel';
import * as serviceWorker from './serviceWorker';
import ToastPortal from './components/toast/ToastPortal';
import { store } from './store';
import App from './App';
import packageJSON from '../package.json';

import './index.css';
import './config/Cognito/index';

import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl-controls/lib/controls.css';
import './fonts/inter/inter.css';
import './fonts/rubik/rubik.css';
import './fonts/global-fonts.css';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_NODE_ENV,
    tracesSampleRate: 0.1,
    release: packageJSON.version,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      // sentry user feedback widget.
      // Sentry.feedbackIntegration({
      //   colorScheme: 'light',
      // }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: ['https://api.listwithclever.com/'],
        networkRequestHeaders: ['X-Custom-Header', 'Cache-Control'],
        networkResponseHeaders: ['X-Custom-Header', 'Referrer-Policy'],
      }),
    ],
  });
}

MixpanelUtils.init();

const container =
  document.getElementById('root') || document.createElement('div');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      <ToastPortal />
    </React.StrictMode>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
