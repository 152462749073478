export enum CommissionModel {
  FlatFee = 1,
  OnePercent = 2,
  Less125k = 3,
  Less75k = 4,
  BuyerNoCashBack = 5,
  Traditional = 6,
  Buyer1kRebate = 7,
  Buyer1Percent = 8,
  BuyerTiered = 9,
  CleverCashBackHalfPercent = 10,
  CleverCashBack250 = 11,
  OneAndHalfPercent = 12,
  BuyerModelNew = 13,
  MrCooper = 14,
  MrCooperBuyer = 15,
  MrCooperSeller = 16,
  GravyBuyer = 17,
}

export const commissionModelLabelById = {
  [CommissionModel.FlatFee]: 'Flat Fee Model',
  [CommissionModel.OnePercent]: '1% Model',
  [CommissionModel.Less125k]: '<125k Model',
  [CommissionModel.Less75k]: '<75k Model',
  [CommissionModel.BuyerNoCashBack]: 'Buyer No Clever Cash Back',
  [CommissionModel.Traditional]: 'Traditional Model',
  [CommissionModel.Buyer1kRebate]: 'Buyer $1K Rebate Model',
  [CommissionModel.Buyer1Percent]: 'Buyer 1% Rebate Model',
  [CommissionModel.BuyerTiered]: 'Buyer Tiered Rebate Model',
  [CommissionModel.CleverCashBackHalfPercent]: 'Clever Cash Back (0.5%)',
  [CommissionModel.CleverCashBack250]: 'Clever Cash Back ($250)',
  [CommissionModel.OneAndHalfPercent]: '1.5% Model',
  [CommissionModel.BuyerModelNew]: 'Buyer Model (new $250)',
  [CommissionModel.MrCooper]: 'Mr Cooper CCB',
  [CommissionModel.MrCooperBuyer]: 'Mr Cooper Buyer CCB',
  [CommissionModel.MrCooperSeller]: 'Mr Cooper Seller CCB',
  [CommissionModel.GravyBuyer]: 'Gravy Buyer',
};

export const commissionModels = Object.entries(commissionModelLabelById).map(
  ([key, label]) => ({
    label,
    value: Number(key) as CommissionModel,
  }),
);

export const buyerModels = <const>[
  {
    label: 'Buyer No Clever Cash Back',
    value: CommissionModel.BuyerNoCashBack,
  },
  { label: 'Buyer $1K Rebate Model', value: CommissionModel.Buyer1kRebate },
  { label: 'Buyer 1% Rebate Model', value: CommissionModel.Buyer1Percent },
  { label: 'Buyer Tiered Rebate Model', value: CommissionModel.BuyerTiered },
  {
    label: 'Clever Cash Back (0.5%)',
    value: CommissionModel.CleverCashBackHalfPercent,
  },
  {
    label: 'Clever Cash Back ($250)',
    value: CommissionModel.CleverCashBack250,
  },
  { label: 'Buyer Model (new $250)', value: CommissionModel.BuyerModelNew },
  { label: 'Mr Cooper CCB', value: CommissionModel.MrCooper },
  { label: 'Mr Cooper Buyer CCB', value: CommissionModel.MrCooperBuyer },
  { label: 'Gravy Buyer', value: CommissionModel.GravyBuyer },
];

export const sellerModels = <const>[
  { label: 'Flat Fee Model', value: CommissionModel.FlatFee },
  { label: '1% Model', value: CommissionModel.OnePercent },
  { label: '<125k Model', value: CommissionModel.Less125k },
  { label: '<75k Model', value: CommissionModel.Less75k },
  { label: 'Traditional Model', value: CommissionModel.Traditional },
  { label: '1.5% Model', value: CommissionModel.OneAndHalfPercent },
  { label: 'Mr Cooper Seller CCB', value: CommissionModel.MrCooperSeller },
];

export const commissionModelDefaultTemplateHashmap = <const>{
  'Flat Fee Model': 'commission_model_seller_3k',
  '1% Model': 'commission_model_seller_1%',
  '<125k Model': 'commission_model_seller_traditional',
  '<75k Model': 'commission_model_seller_traditional',
  'Buyer No Clever Cash Back': '',
  'Traditional Model': '',
  'Buyer $1K Rebate Model': 'commission_model_buyer_1k',
  'Buyer 1% Rebate Model': 'commission_model_buyer_1%',
  'Buyer Tiered Rebate Model': '',
  'Clever Cash Back ($250)': 'commission_model_ccb_250',
  'Clever Cash Back (0.5%)': 'commission_model_ccb_0.5',
  '1.5% Model': 'commission_model_seller_1.5%',
  'Buyer Model (new $250)': 'commission_model_new_buyer_model',
};
