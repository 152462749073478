import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';

export const getTemplates = createAsyncThunk<any>(
  'templates/getTemplates',
  async () => {
    const response = await instance.get(`/deals/templates/`);

    return response.data;
  },
);
