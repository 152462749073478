import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {
  CommissionModelOption,
  PresetFilters,
  TagOption,
} from '../../types/searchFilters';
import { getSearchFilters } from './actions';
import { FilterTypes } from '../../enums/FilterTypes';
import { isBoolean, isNumber } from '../../utils/dataType';

function unwrapCookieBoolean(
  cookie: string | undefined,
  defaultValue: boolean,
) {
  if (cookie === 'true') return true;
  if (cookie === 'false') return false;
  return defaultValue;
}

export type SearchFiltersState = {
  loading: boolean;
  error: unknown;
  presetFilters: PresetFilters | null;
  selectedCommissionModels: CommissionModelOption[];
  tags: TagOption[];
  priceLow: number | null;
  priceHigh: number | null;
  eligible: boolean;
  signRate: number | null;
  displayFormSubmissions: boolean;
  displayHomeBases: boolean;
  displayTransactions: boolean;
  smartSortOn: boolean;
  filtersNeedsUpdating: boolean;
};

const initialState: SearchFiltersState = {
  loading: false,
  error: null,
  presetFilters: null,
  selectedCommissionModels: [],
  tags: [],
  eligible: true,
  priceLow: null,
  priceHigh: null,
  signRate: null,
  displayFormSubmissions: unwrapCookieBoolean(
    Cookies.get('displayFormSubmissions'),
    false,
  ),
  displayHomeBases: unwrapCookieBoolean(Cookies.get('displayHomeBases'), true),
  displayTransactions: unwrapCookieBoolean(
    Cookies.get('displayTransactions'),
    true,
  ),
  smartSortOn: false,
  filtersNeedsUpdating: false,
};

function isCommissionModelArray(
  value: unknown,
): value is CommissionModelOption[] {
  return (
    Array.isArray(value) &&
    value.every(
      (item) =>
        typeof item.label === 'string' && typeof item.value === 'number',
    )
  );
}

function isTagOptionArray(value: unknown): value is TagOption[] {
  return (
    Array.isArray(value) &&
    value.every(
      (item) =>
        typeof item.label === 'string' && typeof item.value === 'string',
    )
  );
}

const searchFiltersSlice = createSlice({
  name: 'searchFilters',
  initialState,
  reducers: {
    resetFilters(state) {
      state.eligible = true;
      state.priceHigh = null;
      state.priceLow = null;
      state.signRate = null;
      state.tags = [];
      state.selectedCommissionModels = [];
      state.filtersNeedsUpdating = true;
    },
    updateFilters(
      state: SearchFiltersState,
      {
        payload,
      }: {
        payload: {
          filterType: FilterTypes;
          value:
            | boolean
            | number
            | null
            | CommissionModelOption[]
            | TagOption[];
        };
      },
    ) {
      const { filterType, value } = payload;
      switch (filterType) {
        case FilterTypes.eligible:
          if (isBoolean(value)) state.eligible = value;
          break;
        case FilterTypes.priceHigh:
          if (isNumber(value) || value === null) state.priceHigh = value;
          break;
        case FilterTypes.priceLow:
          if (isNumber(value) || value === null) state.priceLow = value;
          break;
        case FilterTypes.selectedCommissionModels:
          if (isCommissionModelArray(value))
            state.selectedCommissionModels = value;
          break;
        case FilterTypes.signRate:
          if (isNumber(value) || value === null) state.signRate = value;
          break;
        case FilterTypes.tags:
          if (isTagOptionArray(value)) state.tags = value;
          break;
        default:
          break;
      }
      state.filtersNeedsUpdating = true;
    },
    fireSetFilters(state, action) {
      state.filtersNeedsUpdating = action.payload;
    },
    toggleFormSubmissions(state) {
      state.displayFormSubmissions = !state.displayFormSubmissions;
      Cookies.set('displayFormSubmissions', `${state.displayFormSubmissions}`);
      state.filtersNeedsUpdating = true;
    },
    toggleHomeBases(state) {
      state.displayHomeBases = !state.displayHomeBases;
      Cookies.set('displayHomeBases', `${state.displayHomeBases}`);
      state.filtersNeedsUpdating = true;
    },
    toggleTransactions(state) {
      state.displayTransactions = !state.displayTransactions;
      Cookies.set('displayTransactions', `${state.displayTransactions}`);
      state.filtersNeedsUpdating = true;
    },
    toggleSmartSortedAgents(state) {
      state.smartSortOn = !state.smartSortOn;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchFilters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSearchFilters.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.presetFilters = payload;
    });
    builder.addCase(getSearchFilters.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  updateFilters,
  resetFilters,
  fireSetFilters,
  toggleFormSubmissions,
  toggleHomeBases,
  toggleSmartSortedAgents,
  toggleTransactions,
} = searchFiltersSlice.actions;

export default searchFiltersSlice.reducer;
