import { createSlice } from '@reduxjs/toolkit';
import {
  PresignedUrls,
  getPresignedUrls,
  getLocationResponse,
} from './actions';

export interface AgentMapMetrics {
  agentId: number;
  nearestTransaction: number;
}

interface AgentMapMetricsMap {
  [agentId: number]: AgentMapMetrics;
}

// FIXME - type this into => interface MapStoreState {}

export interface MapStoreState {
  loading: boolean;
  latitude: number | null;
  longitude: number | null;
  locationResponse: {
    state: string;
    city: string;
    zipcode: string;
    latitude: string;
    longitude: string;
  } | null;
  runAddressSearch: boolean;
  localAddress: string | null;
  addressCoords: {
    latitude: number;
    longitude: number;
  } | null;
  highlightedCell: number | null;
  unhighlightedCell: number | null;
  // Nearby Agents
  smartSortedAgents: any[];
  nearbyAgents: any[];
  agentMapMetricsMap: AgentMapMetricsMap;
  // Update sidebar
  sidebarNeedsUpdating: boolean;
  // Pins
  clearPins: boolean;
  // Update Market Notes
  fetchMarketNotes: boolean;
  // Update Sourcing Territories
  fetchSourcingTerritories: boolean;
  // Presigned urls
  presignedUrls: PresignedUrls | null;
  // Number of Nearby Agents shown in agent list
  numberOfAgents: number;
}

const initialState: MapStoreState = {
  loading: false,
  latitude: null,
  longitude: null,
  // FIXME - type this locationResponse
  locationResponse: null,
  runAddressSearch: false,
  localAddress: null,
  addressCoords: null,
  highlightedCell: null,
  unhighlightedCell: null,
  // Filters
  // Nearby Agents
  smartSortedAgents: [],
  nearbyAgents: [],
  agentMapMetricsMap: {},
  // Update sidebar
  sidebarNeedsUpdating: false,
  // Pins
  clearPins: false,
  // Update Market Notes
  fetchMarketNotes: false,
  // Update Sourcing Territories
  fetchSourcingTerritories: false,
  // Presigned urls
  presignedUrls: null,
  // Number of Nearby Agents shown in agent list
  numberOfAgents: 10,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setLatLng(state, action) {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    updateClearPins(state, action) {
      state.clearPins = action.payload;
    },
    fireUpdateSidebar(state, action) {
      state.sidebarNeedsUpdating = action.payload;
    },
    updateMarketNotes(state, action) {
      state.fetchMarketNotes = action.payload;
    },
    updateSourcingTerritories(state, action) {
      state.fetchSourcingTerritories = action.payload;
    },
    highlightCell(state, action) {
      state.highlightedCell = action.payload;
      state.unhighlightedCell = null;
    },
    unhighlightCell(state, action) {
      state.highlightedCell = null;
      state.unhighlightedCell = action.payload;
    },
    resetNearbyAgents(state) {
      state.nearbyAgents = [];
    },
    updateNearbyAgents(state, action) {
      state.nearbyAgents = action.payload;
      // payload is a list of agent transaction data. each record is a unique agent.
      state.agentMapMetricsMap = action.payload.reduce((acc, agent) => {
        acc[agent.id] = {
          agentId: agent.id,
          nearestTransaction: agent.distance,
        };
        return acc;
      }, {} as AgentMapMetricsMap);
      state.loading = false;
    },
    updateNumberOfAgents(state, action) {
      state.numberOfAgents = action.payload;
    },
    updateSmartSortedAgents(state, action) {
      state.smartSortedAgents = action.payload;
      state.loading = false;
    },
    resetLocalSearch(state) {
      state.locationResponse = null;
    },
  },
  extraReducers: (builder) => {
    // Fetch Location Object
    builder.addCase(getLocationResponse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocationResponse.fulfilled, (state, { payload }) => {
      state.runAddressSearch = true;
      state.locationResponse = payload as any;
      state.loading = false;
    });
    builder.addCase(getLocationResponse.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPresignedUrls.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPresignedUrls.fulfilled, (state, { payload }) => {
      state.presignedUrls = payload;
      state.loading = false;
    });
    builder.addCase(getPresignedUrls.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setLatLng,
  resetNearbyAgents,
  updateNearbyAgents,
  updateNumberOfAgents,
  updateSmartSortedAgents,
  highlightCell,
  unhighlightCell,
  resetLocalSearch,
  fireUpdateSidebar,
  updateClearPins,
  updateMarketNotes,
  updateSourcingTerritories,
} = mapSlice.actions;

export default mapSlice.reducer;
