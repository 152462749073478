import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import { PastSale } from '../../interfaces/pastSale';

/// /
// Async Thunk Actions
/// /
export const fetchPastSaleById = createAsyncThunk<PastSale, string | number>(
  'pastSales/fetchPastSaleById',
  async (transactionId) => {
    const response = await instance.get(`/deals/past-sale/${transactionId}/`);
    return response.data;
  },
);
