import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import Agent from '../../interfaces/agent';
import { CommunicationNote } from '../../interfaces/communication';

/// /
// Async Thunk Actions
/// /
export const fetchCommunicationNote = createAsyncThunk<
  CommunicationNote,
  string | number
>('agentNotes/fetchCommunicationNote', async (noteId) => {
  const response = await instance.get(`/communications/note/${noteId}/`);
  return response.data;
});

export const createCommunicationNote = createAsyncThunk<CommunicationNote, any>(
  'agentNotes/createCommunicationNote',
  async (noteObj) => {
    const { agentId, note } = noteObj;

    const response = await instance.post(`/communications/note/`, {
      note,
      agent: agentId,
    });
    return response.data;
  },
);

export const updateCommunicationNote = createAsyncThunk<CommunicationNote, any>(
  'agentNotes/updateCommunicationNote',
  async (noteData) => {
    const noteId = noteData.id;
    const { note } = noteData;
    const response = await instance.patch(`/communications/note/${noteId}/`, {
      note,
    });
    return response.data;
  },
);

export const deleteCommunicationNote = createAsyncThunk<
  CommunicationNote,
  number
>('agentNotes/deleteCommunicationNote', async (noteId) => {
  const response = await instance.delete(`/communications/note/${noteId}/`);
  return response.data;
});

export const refreshAgent = createAsyncThunk<Agent, number>(
  'agentNotes/refreshAgent',
  async (agentId) => {
    const response = await instance.get(`/deals/agent/${agentId}`);
    return response.data;
  },
);
