import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import { OutboundLiveTransferRecord } from '../../interfaces/search';

export const createOltRecord = createAsyncThunk<
  OutboundLiveTransferRecord,
  any
>('outboundLiveTransfer/createOltRecord', async (payload, { getState }) => {
  const { notes } = payload;
  const state: any = getState();
  const { oltRecordPayload, outbound_live_transfer_invitation } =
    state.outboundLiveTransfer;
  const response = await instance.post('/deals/olt-record/', {
    ...oltRecordPayload,
    notes,
    outbound_live_transfer_invitation: outbound_live_transfer_invitation.id,
  });
  return response.data;
});
