import { createSlice } from '@reduxjs/toolkit';
import { cancelInvitationById, postInvitation } from './actions';

interface InvitationState {
  hasErrors: boolean;
  loading: boolean;
  inviteSent: boolean;
  invitationMap: any;
  invitationError: any;
}

const initialState: InvitationState = {
  hasErrors: false,
  loading: false,
  inviteSent: false,
  invitationMap: {},
  invitationError: null,
};

const invitationSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    acceptedInvitation(state) {
      state.inviteSent = false;
    },
    clearInvitationErrors(state) {
      state.invitationError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postInvitation.pending, (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.inviteSent = false;
      state.invitationError = null;
    });
    builder.addCase(postInvitation.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.invitationError = payload.data;
        state.hasErrors = true;
      } else {
        state.invitationMap[`${payload.agent}`] = { ...payload };
        state.invitationError = null;
        state.hasErrors = false;
        state.inviteSent = true;
      }
    });
    builder.addCase(postInvitation.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });

    builder.addCase(cancelInvitationById.pending, (state) => {
      state.loading = true;
      state.inviteSent = false;
    });
    builder.addCase(cancelInvitationById.fulfilled, (state) => {
      state.inviteSent = true;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(cancelInvitationById.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
  },
});

export const { acceptedInvitation, clearInvitationErrors } =
  invitationSlice.actions;

export default invitationSlice.reducer;
