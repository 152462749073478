import nunjucks from 'nunjucks';
import { Deal } from '../interfaces/search';
import { ContextType } from '../enums/ContextType';
import { commissionModelDefaultTemplateHashmap } from '../enums/commission_models';
import Agent from '../interfaces/agent';

const renderDjangoTemplate = (template: string, context: any) => {
  nunjucks.configure({ autoescape: true });
  if (!template) {
    return '';
  }
  const renderedString = nunjucks.renderString(template, context);
  return renderedString;
};

const getDjangoKeyListFromObject = (type: string, object: any, depth = 2) => {
  if (object === null) {
    return [];
  }
  let keyList = [] as any[];
  const keys = Object.keys(object);
  if (depth > 1) {
    keyList = keys.map((key) => {
      if (!object[key]) {
        return '';
      }
      if (Array.isArray(object[key])) {
        return '';
      }
      if (typeof object[key] !== 'object') {
        return object[key];
      }
      const individualKeys = Object.keys(object[key]);
      return individualKeys;
    });
  }

  const typeMap = [] as any[];
  for (let i = 0; i < keys.length; i += 1) {
    const typeVal = keys[i];
    if (keyList.length > 0) {
      for (let t = 0; t < keyList[i].length; t += 1) {
        typeMap.push(`${type}.${typeVal}.${keyList[i][t]}`);
      }
    }
    typeMap.push(`${type}.${keys[i]}`);
  }
  return typeMap;
};

const getContextForDeal = (
  contextType: ContextType,
  deal: Deal,
  templates: any,
  agent: Agent,
) => {
  let context: any = { deal: { ...deal }, agent: { ...agent, bio: '' } };

  // Add commission model template based off the templates list
  const templateKey =
    commissionModelDefaultTemplateHashmap[deal?.commission_model_name];
  if (templates[templateKey]) {
    const { template } = templates[templateKey];
    context = {
      ...context,
      commission_model: renderDjangoTemplate(template || '', context),
    };
  }
  return context;
};

const filterTemplatesByTransactionType = (templateList, transactionType) =>
  templateList.filter(
    (value) =>
      value.value.tags && value.value.tags.indexOf(transactionType) > -1,
  );

const filterTemplatesByEmptyContext = (templateList) =>
  templateList.filter(
    (value) => value.value.context !== null && value.value.context.length > 0,
  );

const getDefaultEmailTemplateForDeal = (deal: Deal, templates: any) => {
  const EmailTemplateSellerConstant =
    'New email introduction template for sellers';
  const EmailTemplateBuyerConstant =
    'New email introduction template for buyers';
  const EmailCashBuyer15PercentTemplateConstant =
    'Intro Email For Cash Buyer for 1.5% fee';
  if (!templates) {
    return null;
  }
  const keys = Object.keys(templates);
  let emailTemplateList = keys.reduce((result, option) => {
    if (templates[option].tags.indexOf('email') > -1) {
      result.push({
        label: templates[option].description,
        value: templates[option],
      });
    }
    return result;
  }, [] as any);

  const transactionType = deal?.transaction_type === 'Buy' ? 'buyer' : 'seller';

  emailTemplateList = filterTemplatesByEmptyContext(
    filterTemplatesByTransactionType(emailTemplateList, transactionType),
  );

  let defaultTemplate: string;

  if (deal?.transaction_type === 'Buy') {
    [defaultTemplate] = emailTemplateList.filter(
      (item: any) => item.label === EmailTemplateBuyerConstant,
    );
  } else {
    [defaultTemplate] = emailTemplateList.filter(
      (item: any) => item.label === EmailTemplateSellerConstant,
    );
  }
  if (
    deal?.seller_type === 'Cash Buyer' &&
    deal?.commission_model_name === 'Flat Fee Model'
  ) {
    const indexOfTemplate = emailTemplateList.findIndex(
      (temp) => temp.label === EmailCashBuyer15PercentTemplateConstant,
    );
    return emailTemplateList[indexOfTemplate];
  }
  if (
    deal?.seller_type === 'Cash Buyer' &&
    (deal?.commission_model_name === '1% Model' ||
      deal?.commission_model_name === '1.5% Model')
  ) {
    const indexOfTemplate = emailTemplateList.findIndex(
      (temp) => temp.label === EmailCashBuyer15PercentTemplateConstant,
    );
    return emailTemplateList[indexOfTemplate];
  }
  return defaultTemplate;
};

const getDefaultSmsTemplateForDeal = (deal: Deal, templates: any) => {
  const SMSDefaultTemplateConstant =
    'New sms introduction template for both buyer and sellers';
  const SMSCashBuyer15PercentTemplateConstant =
    'Cash Buyer Intro for 1.5% sellers (updated for new price change)';

  const BiggerPocketsConstant = 'Intro group text for BiggerPockets buyers';

  // Filter templates by email/sms tags
  const keys = Object.keys(templates);
  let smsTemplateList: any[] = keys.reduce((result, option) => {
    if (templates[option].tags.indexOf('sms') > -1) {
      result.push({
        label: templates[option].description,
        value: templates[option],
      });
    }
    return result;
  }, [] as any);

  const transactionType = deal?.transaction_type === 'Buy' ? 'buyer' : 'seller';

  smsTemplateList = filterTemplatesByEmptyContext(
    filterTemplatesByTransactionType(smsTemplateList, transactionType),
  );

  if (deal?.customer && deal?.customer.vendor === 'BiggerPockets') {
    const indexOfTemplate = smsTemplateList.findIndex(
      (temp) => temp.label === BiggerPocketsConstant,
    );
    if (indexOfTemplate > -1) {
      return smsTemplateList[indexOfTemplate];
    }
  }

  if (
    deal?.seller_type === 'Cash Buyer' &&
    deal?.commission_model_name === 'Flat Fee Model'
  ) {
    const indexOfTemplate = smsTemplateList.findIndex(
      (temp) => temp.label === SMSCashBuyer15PercentTemplateConstant,
    );
    return smsTemplateList[indexOfTemplate];
  }
  if (
    deal?.seller_type === 'Cash Buyer' &&
    (deal?.commission_model_name === '1% Model' ||
      deal?.commission_model_name === '1.5% Model')
  ) {
    const indexOfTemplate = smsTemplateList.findIndex(
      (temp) => temp.label === SMSCashBuyer15PercentTemplateConstant,
    );
    return smsTemplateList[indexOfTemplate];
  }
  return smsTemplateList.filter(
    (item: any) => item.label === SMSDefaultTemplateConstant,
  )[0];
};

export {
  renderDjangoTemplate,
  getDjangoKeyListFromObject,
  filterTemplatesByEmptyContext,
  filterTemplatesByTransactionType,
  getDefaultEmailTemplateForDeal,
  getDefaultSmsTemplateForDeal,
  getContextForDeal,
};
