import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';

const ToastPortal: React.FC = () => {
  const ToastDomEl =
    document.getElementById('toastPortal') ?? document.createElement('section');

  return createPortal(<ToastContainer />, ToastDomEl);
};

ToastPortal.displayName = 'ToastPortal';
export default memo(ToastPortal);
