import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import { FormSubmission } from '../../interfaces/formSubmission';

/// /
// Async Thunk Actions
/// /
export const fetchFormSubmissionByAddress = createAsyncThunk<
  FormSubmission,
  string
>('formSubmissions/fetchPastSaleById', async (address) => {
  const response = await instance.get(
    `/deals/form-submissions/?seed_location=${encodeURIComponent(
      address.trim(),
    )}`,
  );
  return response.data;
});
