import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { postDirectMatch } from './actions';
import Agent from '../../interfaces/agent';

interface DirectMatchState {
  isDirectMatchModalOpen: boolean;
  hasErrors: boolean;
  loading: boolean;
  directMatchSent: boolean;
  directMatch: any;
  directMatchErrors: any;
  selectedAgent: Agent | null;
  selectedAgentSourcingType: string | null;
  selectedAgentDisableComms: boolean;
}

const initialState: DirectMatchState = {
  isDirectMatchModalOpen: false,
  hasErrors: false,
  loading: false,
  directMatchSent: false,
  directMatch: {},
  directMatchErrors: null,
  selectedAgent: null,
  selectedAgentSourcingType: null,
  selectedAgentDisableComms: false,
};

const directMatchSlice = createSlice({
  name: 'directMatches',
  initialState,
  reducers: {
    setSelectAgent(
      state,
      action: PayloadAction<DirectMatchState['selectedAgent']>,
    ) {
      state.selectedAgent = action.payload;
    },
    setSelectAgentSourcingType(
      state,
      action: PayloadAction<DirectMatchState['selectedAgentSourcingType']>,
    ) {
      state.selectedAgentSourcingType = action.payload;
    },
    setSelectAgentDisableComms(
      state,
      action: PayloadAction<DirectMatchState['selectedAgentDisableComms']>,
    ) {
      state.selectedAgentDisableComms = action.payload;
    },
    directMatchAccepted(state) {
      state.directMatchSent = false;
    },
    clearDirectMatchErrors(state) {
      state.directMatchErrors = null;
    },
    toggleDirectMatchModal(state) {
      state.isDirectMatchModalOpen = !state.isDirectMatchModalOpen;
    },
    setDirectMatchModal(
      state,
      action: PayloadAction<DirectMatchState['isDirectMatchModalOpen']>,
    ) {
      state.isDirectMatchModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postDirectMatch.pending, (state) => {
      state.loading = true;
      state.directMatchSent = false;
      state.directMatchErrors = null;
      state.hasErrors = false;
    });
    builder.addCase(postDirectMatch.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        state.directMatchErrors = payload.data;
      } else {
        state.directMatchErrors = null;
        state.hasErrors = false;
        state.directMatchSent = true;
      }
    });
    builder.addCase(postDirectMatch.rejected, (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.directMatchErrors = payload;
    });
  },
});
export const {
  setSelectAgent,
  setSelectAgentSourcingType,
  setSelectAgentDisableComms,
  directMatchAccepted,
  clearDirectMatchErrors,
  setDirectMatchModal,
  toggleDirectMatchModal,
} = directMatchSlice.actions;

export default directMatchSlice.reducer;
