import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import Agent from '../../interfaces/agent';

/// /
// Async Thunk Actions
/// /
export const fetchAgentById = createAsyncThunk<Agent, string | number>(
  'agents/fetchAgentById',
  async (agentId) => {
    const response = await instance.get(`/deals/agent/${agentId}/`);
    return response.data;
  },
);

export const patchAgent = createAsyncThunk<Agent, any>(
  'agents/patchAgent',
  async (agent) => {
    const agentId = agent.id;
    const { data } = agent;
    const response = await instance.patch(`/deals/agent/${agentId}/`, {
      ...data,
    });
    return response.data;
  },
);
