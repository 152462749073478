import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import instance from '../../axiosInstance';
import Search from '../../interfaces/search';
import Invitation, {
  InvitationPayload,
  InvitationCancellationPayload,
  InvitationCreationError,
} from '../../interfaces/invitation';
import { getSearchFilters } from '../searchFilters/actions';

export interface ErrorObject {
  hasError: boolean;
  errorMessage: string;
}
export interface SearchPropsPayload {
  [key: string]: any;
}

/// /
// Error Handling
/// /
const handleSearchErrors = (err: any) => {
  captureException(err);

  const typedErr: any = err;
  if (
    typedErr.response.status === 500 ||
    typedErr.response.status === 400 ||
    typedErr.response.status === 404
  ) {
    let message = '';
    switch (typedErr.response.status) {
      case 500:
        message = 'There was an error with the server';
        break;
      case 400:
        message = 'There was an error that occurred';
        break;
      case 404:
        message = 'The search you are looking for does not exist';
        break;
      default:
        break;
    }
    return {
      error: true,
      errorData: {
        message: `An Error has occurred. Code: ${typedErr.response.status}\n \n \nMessage: ${message}`,
      },
    };
  }
  return {
    error: true,
    errorData: typedErr.response.data,
  };
};

/// /
// Async Thunk Actions
/// /
export const fetchSearchById = createAsyncThunk<Search, string>(
  'searches/fetchSearchById',
  async (searchId, { rejectWithValue, dispatch }) => {
    try {
      const response = await instance.get<Search>(
        `/deals/search/${searchId}/?format=json`,
      );
      const search = response.data;
      await dispatch(getSearchFilters(search.id));
      try {
        const defaultPlaybookResponse = await instance.get(
          `/deals/search/${search.id}/default-playbook/`,
        );
        search.default_playbook = defaultPlaybookResponse.data || null;
        return search;
      } catch (e) {
        search.default_playbook = undefined;
        return search;
      }
    } catch (err) {
      return rejectWithValue(handleSearchErrors(err));
    }
  },
);

export const patchSearchById = createAsyncThunk<
  Search | any,
  [number | string, SearchPropsPayload]
>('searches/patchSearchById', async (tuple, { rejectWithValue }) => {
  try {
    const searchId = tuple[0];
    const props = tuple[1];
    const response = await instance.patch(
      `/deals/search/${searchId}/?format=json`,
      { ...props },
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(handleSearchErrors(err));
  }
});

export const endSearchActivity = createAsyncThunk<any, string | number>(
  'searches/endSearchActivity',
  async (activityId) => {
    const response = await instance.get(
      `/deals/search-activity/${activityId}/?end=true`,
    );
    if (response.status === 200) {
      return response.data;
    }
    return {
      hasError: true,
      errorMessage: 'There was an error ending the search',
    };
  },
);

export const patchDealById = createAsyncThunk<any, any>(
  'searches/patchDealById',
  async (patchParams) => {
    try {
      const response = await instance.patch(`/deals/${patchParams.id}/`, {
        ...patchParams.data,
      });
      return response.data;
    } catch (err) {
      const typedErr: any = err;
      return { error: true, data: typedErr.response.data };
    }
  },
);

export const postInvitation = createAsyncThunk<
  Invitation | any,
  InvitationPayload
>(
  'searches/postInvitation',
  async (properties: InvitationPayload, thunkApi) => {
    try {
      const response = await instance.post(`/deals/invitation/`, {
        ...properties,
      });
      return response.data;
    } catch (err) {
      const typedErr: any = err;
      const invitationError: InvitationCreationError = {
        message:
          typedErr?.response?.data?.message || // 400s have a message
          typedErr?.response?.data?.detail || // 500s have a detail
          'Unknown error',
        agentId: properties.agent,
      };
      return thunkApi.rejectWithValue(invitationError);
    }
  },
);

export const cancelInvitationById = createAsyncThunk<
  any,
  InvitationCancellationPayload
>('searches/cancelInvitation', async (cancellationPayload) => {
  const { invitationId, agentId } = cancellationPayload;
  const response = await instance.get(
    `/deals/invitation/${invitationId}/cancel/`,
  );
  const dataWithIds = { ...response.data, invitationId, agentId };
  return dataWithIds;
});
