/* eslint-disable max-depth */
import { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';

export default function useAuthenticationStatus(): [boolean, boolean] {
  // Set default to true until we can check if they are authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          const accessTokenExpire = session.getAccessToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          // if token has expired refresh it and set authed to true
          if (accessTokenExpire < currentTimeSeconds) {
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            if (authenticatedUser) {
              await authenticatedUser.refreshSession(refreshToken);
              setIsAuthenticated(true);
              setLoading(false);
              return;
            }
            // if this occurs then something weird has happened and then lets just set them to not authenticated
            setIsAuthenticated(false);
            setLoading(false);
            return;
          }
          // tokens are not expired
          setIsAuthenticated(true);
          setLoading(false);
          return;
        }
        setIsAuthenticated(false);
        setLoading(false);
      } catch (e) {
        // if at any point this fails then we are not authenticated
        setIsAuthenticated(false);
        setLoading(false);
      }
    })();
  }, []);

  return [isAuthenticated, loading];
}
