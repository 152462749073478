import { createSlice } from '@reduxjs/toolkit';
import {
  createCommunicationNote,
  deleteCommunicationNote,
  fetchCommunicationNote,
  refreshAgent,
  updateCommunicationNote,
} from './actions';
import Agent from '../../interfaces/agent';
import { CommunicationNote } from '../../interfaces/communication';

interface AgentNotesState {
  hasErrors: boolean;
  loading: boolean;
  agent: Agent | null;
  noteId: number | null;
  openModal: boolean;
  currentEditingNote: CommunicationNote | null;
  successfullyCreated: boolean;
  successfullyDeleted: boolean;
  successfullyUpdated: boolean;
  successfullyRecieved: boolean;
}

const initialState: AgentNotesState = {
  hasErrors: false,
  loading: false,
  agent: null,
  noteId: null,
  openModal: false,
  currentEditingNote: null,
  successfullyCreated: false,
  successfullyDeleted: false,
  successfullyUpdated: false,
  successfullyRecieved: false,
};

const agentNotesSlice = createSlice({
  name: 'agentNotes',
  initialState,
  reducers: {
    setCurrentlyEditingNote(state, action) {
      state.currentEditingNote = action.payload;
    },
    openNotesModal(state, action) {
      state.agent = action.payload;
      state.openModal = true;
    },
    closeNotesModal(state) {
      state.openModal = false;
      state.agent = null;
      state.successfullyCreated = false;
      state.successfullyDeleted = false;
      state.successfullyUpdated = false;
      state.successfullyRecieved = false;
      state.currentEditingNote = null;
    },
  },
  extraReducers: (builder) => {
    // Async note fetch
    builder.addCase(fetchCommunicationNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCommunicationNote.fulfilled, (state, { payload }) => {
      state.currentEditingNote = payload;
      state.successfullyRecieved = true;
      state.loading = false;
    });
    builder.addCase(fetchCommunicationNote.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Delete
    builder.addCase(deleteCommunicationNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCommunicationNote.fulfilled, (state) => {
      state.successfullyDeleted = true;
      state.loading = false;
    });
    builder.addCase(deleteCommunicationNote.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Update
    builder.addCase(updateCommunicationNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCommunicationNote.fulfilled, (state) => {
      state.successfullyUpdated = true;
      state.loading = false;
    });
    builder.addCase(updateCommunicationNote.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Create
    builder.addCase(createCommunicationNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCommunicationNote.fulfilled, (state) => {
      state.successfullyCreated = true;
      state.loading = false;
    });
    builder.addCase(createCommunicationNote.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Refresh Agent
    builder.addCase(refreshAgent.pending, (state) => {
      state.loading = true;
      state.successfullyRecieved = false;
      state.successfullyDeleted = false;
      state.successfullyCreated = false;
      state.successfullyUpdated = false;
    });
    builder.addCase(refreshAgent.fulfilled, (state, { payload }) => {
      state.agent = payload;
      state.loading = false;
    });
    builder.addCase(refreshAgent.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
  },
});

export const { openNotesModal, closeNotesModal, setCurrentlyEditingNote } =
  agentNotesSlice.actions;

// Reducer
export default agentNotesSlice.reducer;
