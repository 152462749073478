import Cookies from 'js-cookie';
import { utcToZonedTime, format } from 'date-fns-tz';
import CryptoJS from 'crypto-js';
import { toast, ToastOptions } from 'react-toastify';
import distance from '@turf/distance';
import moment from 'moment-timezone';
import { AgentOrder } from '../enums/ordering/agent';
import { ContextType } from '../enums/ContextType';
import Agent from '../interfaces/agent';
import { NewBioContext } from '../store/agentModal/reducer';
import Search, {
  LocationObject,
  OutboundLiveTransferInvitation,
  SearchAgentMetricsMap,
} from '../interfaces/search';
import Invitation, { SearchAgentInvitationMap } from '../interfaces/invitation';
import { TransactionOrder } from '../enums/ordering/transaction';
import { CommissionModelOption } from '../types/searchFilters';
import { commissionModels } from '../enums/commission_models';

const sendToast = (text: string, args?: ToastOptions) => {
  const defaultArgs: ToastOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };
  toast(text, { ...defaultArgs, ...args });
};

const isAuthenticated = (): boolean =>
  // they are authenticated if access token exists OR the refresh exists but if both are gone then they are not authenticated
  Cookies.get('access') !== undefined || Cookies.get('refresh') !== undefined;
const getDaysBetween = (date): number => {
  const timeBetween = new Date().getTime() - new Date(date).getTime();
  return Math.round(timeBetween / (1000 * 3600 * 24));
};

const formatDateToTimeZone = (formatDate: string) => {
  const utcDate = new Date(formatDate);
  if (!Number.isNaN(utcDate.getTime())) {
    // eslint-disable-next-line prefer-destructuring
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(utcDate, timeZone);
    return format(zonedDate, "MMM do 'at' p z", { timeZone });
  }
  return null;
};

/*
  Updates are required ever 7 days
  - if 5 - 6 days from last update = update due in x days (yellow)
  - if due 7 days today = update due today (red)
  - if >= 8 days = update due x days ago (red)
*/
const getUpdateStatus = (date: string | null): string => {
  if (!date) {
    return '';
  }
  const daysBetween = getDaysBetween(date);
  if (daysBetween >= 5 && daysBetween <= 6) {
    const daysDue = 7 - daysBetween;
    const daysText = daysDue === 1 ? 'day' : 'days';
    return `Update due in ${daysDue} ${daysText}`;
  }
  if (daysBetween === 7) {
    return 'Update due today';
  }
  const daysDue = daysBetween - 7;
  const daysText = daysDue === 1 ? 'day' : 'days';
  return `Update due ${daysDue} ${daysText} ago`;
};
/*
  Due 10 days after closing date
*/
const getPaymentDueStatus = (date: string | null): string => {
  if (!date) {
    return '';
  }
  const daysBetween = getDaysBetween(date);
  if (daysBetween > 10) {
    // payment x days overdue
    const daysDue = daysBetween - 10;
    const daysText = daysDue === 1 ? 'day' : 'days';
    return `Payment ${daysDue} ${daysText} overdue`;
  }
  // payment due in x days
  const daysDue = 10 - daysBetween;
  const daysText = daysDue === 1 ? 'day' : 'days';
  return `Payment due in ${daysDue} ${daysText}`;
};

const getUpdatedStatus = (date: string | null): string => {
  if (!date) {
    return '';
  }
  const daysBetween = getDaysBetween(date);
  const daysText = daysBetween === 1 ? 'day' : 'days';
  return `Last updated: ${daysBetween} ${daysText} ago`;
};

const getPaymentAlertClass = (date: string | null): string => {
  if (!date) {
    return '';
  }
  const daysBetween = getDaysBetween(date);
  return daysBetween > 10 ? 'red' : 'black';
};

const getAlertClass = (date: string | null): string => {
  if (!date) {
    return '';
  }
  const daysBetween = getDaysBetween(date);
  return daysBetween >= 5 && daysBetween <= 6 ? 'yellow' : 'red';
};

const convertDateStringToFormat = (date: string | null): string => {
  if (!date) {
    return '';
  }
  const dtf = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const d = new Date(date);
  const [{ value: mo }, , { value: da }, , { value: ye }] =
    dtf.formatToParts(d);

  return `${mo} ${da} ${ye}`;
};

const getTransactionTypeLabel = (input: string | null) => {
  if (input === 'Sell') {
    return 'Seller Deal';
  }
  if (input === 'Buy') {
    return 'Buyer Deal';
  }
  return null;
};

const generateDistance = (feature, latitude, longitude) => {
  // TODO: clean this up and move to specific mapHelpers file during useMapHelpers refactor
  // I don't like how we're mutating the feature here, this should be a pure function in the rewrite
  const distanceFromDeal = distance([longitude, latitude], feature.geometry, {
    units: 'miles',
  });

  // Priority agents are defined as agents that have a transaction within 10 miles of the deal
  // and a homebase within 30 miles of the deal.
  if (feature.source === 'transactions') {
    let homebaseCoords =
      feature.properties[TransactionOrder.AgentHomebaseLngLat];
    /* eslint-disable */
    try {
      homebaseCoords = JSON.parse(homebaseCoords); // sent as string from backend, so need to parse "[lng, lat]"
    } catch (e) {
      homebaseCoords = null;
    }
    const homebaseDistance = homebaseCoords
      ? distance([longitude, latitude], homebaseCoords, {
          units: 'miles',
        })
      : 100;
    /* eslint-enable */
    const isProximityAgent = distanceFromDeal < 10 && homebaseDistance < 30;
    const signRate = feature.properties[TransactionOrder.SignRate];
    const saleDate = feature.properties[TransactionOrder.SaleDate];
    const tags =
      feature.properties && feature.properties[8]
        ? JSON.parse(feature.properties[8])
        : [];
    Object.defineProperty(feature.properties, 'isProximityAgent', {
      value: isProximityAgent,
      writable: true,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(feature.properties, 'signRate', {
      value: signRate || 0,
      writable: true,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(feature.properties, 'tags', {
      value: tags || [],
      writable: true,
      enumerable: true,
      configurable: true,
    });
    Object.defineProperty(feature.properties, 'sale_date', {
      value: saleDate,
      writable: true,
      enumerable: true,
      configurable: true,
    });
  }

  Object.defineProperty(feature.properties, AgentOrder.Distance, {
    value: distanceFromDeal,
    writable: true,
    enumerable: true,
    configurable: true,
  });
};

const dedupeArray = (
  array: mapboxgl.MapboxGeoJSONFeature[],
  propertiesKey: string,
) => {
  // TODO: type this properly and move to map helpers file
  //       assumed to be an array of feature objects
  const seen = new Set();
  return array.filter((item) => {
    if (!item || !item?.properties) {
      return false;
    }
    const k = item.properties[propertiesKey];
    return seen.has(k) ? false : seen.add(k);
  });
};

const sortDistance = (features) => {
  features.sort((a, b) => {
    if (a.properties[AgentOrder.Distance] > b.properties[AgentOrder.Distance]) {
      return 1;
    }
    if (a.properties[AgentOrder.Distance] < b.properties[AgentOrder.Distance]) {
      return -1;
    }
    return 0; // a must be equal to b
  });
  return features;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/**
 * Deprecated. Pull from package.json instead.
 */
const getVersion = () => 'v2.0.7';

const editKeyName = (object, oldKey, newKey) => {
  // make sure the key name doesnt already exist
  // TODO: update this when profile_links becomes an array.
  if (Object.keys(object).indexOf(newKey) > -1) {
    sendToast('You cannot have a profile name that is the same');
    return object;
  }
  const stringifiedObject = JSON.stringify(object);
  const regex = new RegExp(`"${oldKey}":`, 'g');
  const newString = stringifiedObject.replace(regex, `"${newKey}":`);
  return JSON.parse(newString);
};

const editKeyValue = (object, key, value) => {
  const newObject = { ...object };
  newObject[key] = value;
  return newObject;
};

const getDefaultBioFromList = (
  list: any[],
  contextType: ContextType,
  transactionType: string,
  newlyCreatedBio: NewBioContext | null,
) => {
  let defaultTemplate: any = null;
  let defaultBio = [] as any[];
  if (contextType === ContextType.email) {
    const label =
      newlyCreatedBio !== null && newlyCreatedBio.type === ContextType.email
        ? newlyCreatedBio.name
        : 'default';
    defaultBio = list.filter((item: any) => item.label === label);
  } else {
    let type = 'seller';
    if (transactionType === 'Buy') {
      type = 'buyer';
    }
    type =
      newlyCreatedBio !== null && newlyCreatedBio.type === ContextType.sms
        ? newlyCreatedBio.name
        : type;
    defaultBio = list.filter((item: any) => item.label === type);
  }

  if (defaultBio.length) {
    [defaultTemplate] = defaultBio;
  }
  return defaultTemplate;
};

const isValidPattern = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

const isValidURL = (string) => {
  if (!isValidPattern(string)) {
    return false;
  }
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const isAgentSMSOptOut = (agent: Agent) => {
  if (!agent) {
    return true;
  }
  return !agent.text_consent_opt_in || agent.sms_opt_out;
};

const getTextConsentWording = (agent: Agent) => {
  if (!agent) {
    return '';
  }
  if (!agent.text_consent_opt_in && agent.sms_opt_out) {
    return 'This agent has not not opted in and has opted out of sms';
  }
  if (agent.sms_opt_out) {
    return 'This agent has opted out of sms';
  }
  if (!agent.text_consent_opt_in) {
    return 'This agent has not opted in for text consent.';
  }
  return '';
};

const base64url = (source) => {
  // Encode in classical base64
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
};

const createJWT = (data) => {
  const header = {
    alg: 'HS256',
    typ: 'JWT',
  };
  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);

  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  const encodedData = base64url(stringifiedData);

  const token = `${encodedHeader}.${encodedData}`;

  return token;
};

const formatSecondsToMinutes = (seconds) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  let ret = '';
  if (hrs > 0) {
    ret += `${hrs}hr `;
  }
  ret += `${mins}m ${secs}s`;

  return ret;
};

const agentPastSalesMatchesSearchState = (
  pastSaleStates: string[],
  searchState?: string | null,
  homebase?: LocationObject | null,
) => {
  // Return true if agent home base is in the search state.
  if (homebase && homebase.state === searchState) {
    return true;
  }
  if (!searchState) {
    return false;
  }
  return pastSaleStates.includes(searchState);
};

const convertDollarToFloatValue = (value) =>
  parseFloat(value.replace(/[$,]+/g, '')).toFixed(2);

const formatAMPM = (date) => {
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const minutes = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  return `${hours}:${minutes}${ampm}`;
};

const formatDate = (date) => {
  const d = new Date(date);
  const dateString = `${d.getMonth() + 1}/${d.getDate()}/${d
    .getFullYear()
    .toString()
    .substr(2, 2)} @ ${formatAMPM(d)}`;
  return dateString;
};

const formatHomeValue = (newHomeValue: string): string => {
  let homeValue = newHomeValue;

  if (typeof newHomeValue === 'string') {
    homeValue = newHomeValue.replace(/[^0-9.]+/g, '');
  }

  if (homeValue[0] === '$') {
    // Home Value is already formatted
    return homeValue;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatted = formatter.format(Number(homeValue));
  return formatted;
};

const getUTCDate = (date: Date) => {
  const dtYear = date.getFullYear();
  const dtMonth = date.getMonth();
  const dtDate = date.getDate();
  const ms = Date.UTC(dtYear, dtMonth, dtDate);

  return ms;
};

const convertUTCToLocalDateObject = (dateInt: any) => {
  const date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
  if (typeof dateInt === 'string') {
    return date;
  }
  const msInMinute = 60000;
  const timezoneTimestamp =
    date.getTime() + date.getTimezoneOffset() * msInMinute;
  date.setTime(timezoneTimestamp);
  return date;
};

const convertSecondstoHoursMin = (value: number) => {
  const hr = Math.floor(value / 3600);
  const min = Math.floor((value - hr * 3600) / 60);

  return [hr, min];
};

const createTimeString = (dateString) => {
  const sentAtDate = moment(dateString);
  const sentAtTime = sentAtDate.format('hh:mma');
  const sentAtDay = sentAtDate.format('dddd');
  const sentAtDateString = sentAtDate.format('MM/DD/yyyy');
  return `${sentAtTime} on ${sentAtDay} ${sentAtDateString}`;
};

const calculateSendingTimeStrings = (created_at, delay_time) => {
  const current_time = moment();
  const createdAt = moment(created_at);
  const duration = moment.duration(current_time.diff(createdAt));
  const minutes = duration.minutes();
  const sendingInMinutes = delay_time - minutes;
  createdAt.add(sendingInMinutes, 'minutes');
  const sendingAtString = createTimeString(createdAt);
  return [sendingInMinutes, sendingAtString];
};

const makeSearchAgentInvitationMapFromInvitationList = (
  invitationList: Invitation[],
): SearchAgentInvitationMap =>
  invitationList.reduce((acc, invitation) => {
    acc[invitation.agent] = invitation;
    return acc;
  }, {}) as SearchAgentInvitationMap;

const makeSearchAgentMetricsMapFromSearch = (
  search: Search,
): SearchAgentMetricsMap =>
  search.search_agent_metrics.reduce((acc, metric) => {
    acc[metric.agent_id] = metric;
    return acc;
  }, {}) as SearchAgentMetricsMap;

const makeOltInvitationToOltRecordMap = (olt_records) => {
  if (!olt_records) {
    return {};
  }
  return olt_records.reduce((acc, oltRecord) => {
    acc[oltRecord.outbound_live_transfer_invitation] = oltRecord;
    return acc;
  }, {});
};

const setSelectedCommissionModelsForDeal = (
  selectedModels: CommissionModelOption[],
  commissionModelLabel: string,
): CommissionModelOption[] => {
  const commissionModel: CommissionModelOption | undefined =
    commissionModels.find((model) => model.label === commissionModelLabel);
  if (!commissionModel) {
    return selectedModels;
  }

  if (selectedModels.find((model) => model.label === commissionModelLabel)) {
    return selectedModels;
  }

  // Need to set both 1% and 1.5% models if either is selected
  const newSelectedModels = [...selectedModels, commissionModel];
  if (commissionModelLabel === '1.5% Model') {
    return setSelectedCommissionModelsForDeal(newSelectedModels, '1% Model');
  }
  if (commissionModelLabel === '1% Model') {
    return setSelectedCommissionModelsForDeal(newSelectedModels, '1.5% Model');
  }
  return newSelectedModels;
};

const formatPhoneNumber = (phoneNumber: string | null): string | null => {
  if (!phoneNumber) {
    return null;
  }
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const pattern = /^1?(\d{3})(\d{3})(\d{4})$/;
  const match = pattern.exec(cleaned);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};

const sortOltInvitations = (
  a: OutboundLiveTransferInvitation,
  b: OutboundLiveTransferInvitation,
) => {
  if (a.status === 'CLAIMED' && b.status !== 'CLAIMED') {
    return -1;
  }
  if (a.status !== 'CLAIMED' && b.status === 'CLAIMED') {
    return 1;
  }
  if (a.claimed_at && b.claimed_at) {
    return Date.parse(a.claimed_at) < Date.parse(b.claimed_at) ? -1 : 1; // want to sort based on claimed_at ascending
  }
  return 0;
};

export {
  convertDateStringToFormat,
  dedupeArray,
  editKeyName,
  editKeyValue,
  formatDateToTimeZone,
  formatPhoneNumber,
  generateDistance,
  getAlertClass,
  getDaysBetween,
  getPaymentAlertClass,
  getPaymentDueStatus,
  getTransactionTypeLabel,
  getUpdateStatus,
  getUpdatedStatus,
  isAuthenticated,
  sendToast,
  sortDistance,
  formatter,
  getVersion,
  getDefaultBioFromList,
  isValidURL,
  isAgentSMSOptOut,
  getTextConsentWording,
  createJWT,
  formatSecondsToMinutes,
  agentPastSalesMatchesSearchState,
  convertDollarToFloatValue,
  formatDate,
  formatHomeValue,
  getUTCDate,
  convertUTCToLocalDateObject,
  convertSecondstoHoursMin,
  createTimeString,
  calculateSendingTimeStrings,
  makeSearchAgentInvitationMapFromInvitationList,
  makeSearchAgentMetricsMapFromSearch,
  makeOltInvitationToOltRecordMap,
  setSelectedCommissionModelsForDeal,
  sortOltInvitations,
};
