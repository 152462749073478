import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import instance from '../../axiosInstance';

/// /
// Async Thunk Actions
/// /
export const getMappedAgentData = createAsyncThunk<any>(
  'mappedAgentData/getMappedAgentData',
  async () => {
    const { data } = await instance.get('/deals/mapped-agent-data/');
    return data;
  },
);

export const getMappedAgentDataFromSignedUrl = createAsyncThunk<any, string>(
  'mappedAgentData/getMappedAgentDataFromSignedUrl',
  async (url) => {
    const { data } = await axios.get(url);
    return data;
  },
);
