/* eslint-disable array-callback-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import { DealList } from '../../interfaces/dealList';

export interface FlagsByTerritoryProps {
  pageNumber: number;
  territory: string | null;
}

export interface Filters {
  statuses: number[] | null;
  territory: string[] | null;
  lead_confidence_score: number | null;
  lead_confidence_score_gte: number | null;
  lead_confidence_score_lte: number | null;
  created_at_gte: Date | string | null;
  created_at_lte: Date | string | null;
  conversion_source: string | null;
  needs_first_agent: string | null;
  needs_second_agent: string | null;
  page: number | null;
  show_statistics: boolean | null;
  show_filters: boolean | null;
  order: string | null;
}

export interface Statistics {
  deals_needing_first_agent: number;
  deals_needing_hardsource: number;
  deals_needing_second_agent: number;
  deals_running: number;
  first_agent_wait_time: number;
  second_agent_wait_time: number;
}

export const fetchDeals = createAsyncThunk<DealList, number>(
  'deals/fetchDeals',
  async (pageNumber = 1) => {
    const response = await instance.get(`/deals/search/?page=${pageNumber}`);
    return response.data;
  },
);

export const searchDealsById = createAsyncThunk<DealList, string>(
  'deals/searchDealsById',
  async (vid) => {
    const response = await instance.get(`/deals/search/?vid=${vid}`);
    return response.data;
  },
);

export const searchDealsByFilters = createAsyncThunk<DealList, Filters>(
  'deals/searchDealsByFilters',
  async (filters) => {
    const query = Object.keys(filters)
      .map((key) => {
        if (key !== 'statuses' && key !== 'territory' && filters[key]) {
          return `${key}=${filters[key]}`;
        }
        if (key === 'statuses' && filters[key]) {
          return `${filters[key]?.map((val) => `statuses=${val}`).join('&')}`;
        }
        if (key === 'territory' && filters[key]) {
          return `${filters[key]?.map((val) => `territory=${val}`).join('&')}`;
        }
        return null;
      })
      .filter((a) => a)
      .join('&');
    const response = await instance.get(`/deals/search/?${query}`);
    return response.data;
  },
);

export const getDealFlagsByTerritory = createAsyncThunk<
  any,
  FlagsByTerritoryProps
>('deals/getDealFlagsByTerritory', async (props) => {
  let paramString = `?page=${props.pageNumber}`;
  if (props.territory) {
    paramString += `&territory=${props.territory}`;
  }
  const response = await instance.get(`/deals/flag/${paramString}`);
  return response.data;
});

export const acknowledgeDealFlagById = createAsyncThunk<any, number>(
  'deals/acknowledgeDealFlagById',
  async (id) => {
    const response = await instance.patch(`/deals/flag/${id}/`, {
      acknowledged: true,
    });

    return response.data;
  },
);

export const getSearchListStatistics = createAsyncThunk<Statistics>(
  'deals/getSearchListStatistics',
  async () => {
    const response = await instance.get(`/deals/search-statistics/`);
    return response.data;
  },
);

export const getSearchListStatisticsByFilters = createAsyncThunk<
  Statistics,
  Filters
>('deals/getSearchListStatisticsByFilters', async (filters) => {
  const query = Object.keys(filters)
    .map((key) => {
      if (key !== 'statuses' && key !== 'territory' && filters[key]) {
        return `${key}=${filters[key]}`;
      }
      if (key === 'statuses' && filters[key]) {
        return `${filters[key]?.map((val) => `statuses=${val}`).join('&')}`;
      }
      if (key === 'territory' && filters[key]) {
        return `${filters[key]?.map((val) => `territory=${val}`).join('&')}`;
      }
      return null;
    })
    .filter((a) => a)
    .join('&');
  const response = await instance.get(`/deals/search-statistics/?${query}`);
  return response.data;
});
