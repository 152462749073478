import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAgent } from './actions';
import { CreatedAgent } from '../../services/agents/agentsService';
import { CreatedAgentBackendError } from '../../types/createAgent';

interface AgentCreationState {
  openModal: boolean;
  createAgentLoading: boolean;
  createAgentError: CreatedAgentBackendError | null;
  createAgentSuccess: boolean;
  createdAgent: CreatedAgent | null;
}

const initialState: AgentCreationState = {
  openModal: false,
  createAgentLoading: false,
  createAgentError: null,
  createAgentSuccess: false,
  createdAgent: null,
};

const agentCreationSlice = createSlice({
  name: 'agentCreation',
  initialState,
  reducers: {
    setAgentCreationModal: (
      state,
      action: PayloadAction<AgentCreationState['openModal']>,
    ) => {
      state.openModal = action.payload;
    },
    toggleAgentCreationModal: (state) => {
      state.openModal = !state.openModal;
    },
    resetAgentCreation: (state) => {
      state.createAgentLoading = false;
      state.createAgentError = null;
      state.createAgentSuccess = false;
      state.createdAgent = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAgent.pending, (state) => {
      state.createAgentLoading = true;
      state.createAgentError = null;
      state.createAgentSuccess = false;
      state.createdAgent = null;
    });
    builder.addCase(createAgent.rejected, (state, { payload }) => {
      state.createAgentLoading = false;
      state.createAgentError = payload as CreatedAgentBackendError;
      state.createAgentSuccess = false;
      state.createdAgent = null;
    });
    builder.addCase(createAgent.fulfilled, (state, { payload }) => {
      state.createAgentLoading = false;
      state.createAgentError = null;
      state.createAgentSuccess = true;
      state.createdAgent = payload;
    });
  },
});

export const {
  setAgentCreationModal,
  toggleAgentCreationModal,
  resetAgentCreation,
} = agentCreationSlice.actions;

// Reducer
export default agentCreationSlice.reducer;
