import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ConfigState {
  menuOpen: boolean;
}

const initialState: ConfigState = {
  menuOpen: true,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<ConfigState>) => {
      state.menuOpen = action.payload.menuOpen;
    },
    setMenuState: (state, action: PayloadAction<boolean>) => {
      state.menuOpen = action.payload;
    },
    toggleMenuState: (state) => {
      state.menuOpen = !state.menuOpen;
    },
  },
});

export const { setConfig, toggleMenuState, setMenuState } = configSlice.actions;

// Reducer
export default configSlice.reducer;
