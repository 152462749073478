import { createSlice } from '@reduxjs/toolkit';
import Agent from '../../interfaces/agent';
import {
  SourcingTerritory,
  createNewSourcingTerritory,
  deleteSourcingTerritoryById,
  fetchSourcingTerritoriesFromBounds,
  fetchSourcingTerritoryById,
  updateSourcingTerritoryById,
} from './actions';

interface SourcingTerritoriesState {
  hasErrors: boolean;
  loading: boolean;
  agent: Agent | null;
  noteId: number | null;
  openCreateSourcingTerritoryModal: boolean;
  sourcingTerritories: SourcingTerritory[] | null;
  currentEditingSourcingTerritory: SourcingTerritory | null;
  successfullyCreated: boolean;
  successfullyUpdated: boolean;
  successfullyRecieved: boolean;
  successfullyDeletedSourcingTerritory: boolean;
  polygon: any;
  showAllSourcingTerritories: boolean;
}

const initialState: SourcingTerritoriesState = {
  hasErrors: false,
  loading: false,
  agent: null,
  noteId: null,
  openCreateSourcingTerritoryModal: false,
  sourcingTerritories: null,
  currentEditingSourcingTerritory: null,
  successfullyCreated: false,
  successfullyUpdated: false,
  successfullyRecieved: false,
  successfullyDeletedSourcingTerritory: false,
  polygon: null,
  showAllSourcingTerritories: false,
};

const sourcingTerritoriesSlice = createSlice({
  name: 'sourcingTerritories',
  initialState,
  reducers: {
    setDisplayAllSourcingTerritories(state, action) {
      state.showAllSourcingTerritories = action.payload;
    },
    setCurrentSourcingTerritoryPolygon(state, action) {
      state.polygon = action.payload;
    },
    setCurrentEditingSourcingTerritory(state, action) {
      state.currentEditingSourcingTerritory = action.payload;
    },
    openCreateSourcingTerritoryModal(state) {
      state.openCreateSourcingTerritoryModal = true;
    },
    closeCreateSourcingTerritoryModal(state) {
      state.openCreateSourcingTerritoryModal = false;
    },
    resetSourcingTerritorySuccessStates(state) {
      state.successfullyCreated = false;
      state.successfullyUpdated = false;
      state.successfullyRecieved = false;
      state.successfullyDeletedSourcingTerritory = false;
    },
    reset(state) {
      state.agent = null;
      state.successfullyCreated = false;
      state.successfullyUpdated = false;
      state.successfullyRecieved = false;
      state.successfullyDeletedSourcingTerritory = false;
      state.currentEditingSourcingTerritory = null;
    },
  },
  extraReducers: (builder) => {
    // Async market notes fetch
    builder.addCase(fetchSourcingTerritoriesFromBounds.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSourcingTerritoriesFromBounds.fulfilled,
      (state, { payload }) => {
        state.sourcingTerritories = payload.data;
        state.loading = false;
      },
    );
    builder.addCase(fetchSourcingTerritoriesFromBounds.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    builder.addCase(createNewSourcingTerritory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      createNewSourcingTerritory.fulfilled,
      (state, { payload }) => {
        state.successfullyCreated = true;
        state.currentEditingSourcingTerritory = { ...payload };
        state.loading = false;
      },
    );
    builder.addCase(createNewSourcingTerritory.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Fetch market by id
    builder.addCase(fetchSourcingTerritoryById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSourcingTerritoryById.fulfilled,
      (state, { payload }) => {
        state.currentEditingSourcingTerritory = { ...payload };
        state.loading = false;
      },
    );
    builder.addCase(fetchSourcingTerritoryById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Update market by id
    builder.addCase(updateSourcingTerritoryById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateSourcingTerritoryById.fulfilled,
      (state, { payload }) => {
        state.currentEditingSourcingTerritory = { ...payload };
        state.successfullyUpdated = true;
        state.loading = false;
      },
    );
    builder.addCase(updateSourcingTerritoryById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Delete market by id
    builder.addCase(deleteSourcingTerritoryById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSourcingTerritoryById.fulfilled, (state) => {
      state.successfullyDeletedSourcingTerritory = true;
      state.loading = false;
    });
    builder.addCase(deleteSourcingTerritoryById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
  },
});

export const {
  openCreateSourcingTerritoryModal,
  closeCreateSourcingTerritoryModal,
  setCurrentSourcingTerritoryPolygon,
  reset,
  resetSourcingTerritorySuccessStates,
  setCurrentEditingSourcingTerritory,
  setDisplayAllSourcingTerritories,
} = sourcingTerritoriesSlice.actions;

// Reducer
export default sourcingTerritoriesSlice.reducer;
