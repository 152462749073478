import { createSlice } from '@reduxjs/toolkit';
import Agent from '../../interfaces/agent';
import { Tag } from '../../interfaces/tag';
import {
  createTag,
  deleteTag,
  getAllTags,
  getTagById,
  updateTag,
} from './actions';

interface TagsState {
  hasErrors: boolean;
  loading: boolean;
  agent: Agent | null;
  tagId: number | null;
  openModal: boolean;
  editingTag: Tag | null;
  successfullyCreated: boolean;
  successfullyDeleted: boolean;
  successfullyUpdated: boolean;
  successfullyReceived: boolean;
  tags: Tag[];
}

const initialState: TagsState = {
  hasErrors: false,
  loading: false,
  agent: null,
  tagId: null,
  openModal: false,
  editingTag: null,
  successfullyCreated: false,
  successfullyDeleted: false,
  successfullyUpdated: false,
  successfullyReceived: false,
  tags: [],
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    openTagsModal(state, action) {
      state.agent = action.payload;
      state.openModal = true;
    },
    closeTagsModal(state) {
      state.openModal = false;
      state.agent = null;
      state.successfullyCreated = false;
      state.successfullyDeleted = false;
      state.successfullyUpdated = false;
      state.successfullyReceived = false;
    },
    resetTagState(state) {
      state.successfullyCreated = false;
      state.successfullyDeleted = false;
      state.successfullyUpdated = false;
      state.successfullyReceived = false;
      state.editingTag = null;
    },
    setEditingTag(state, action) {
      state.editingTag = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Async tag fetch
    builder.addCase(getTagById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTagById.fulfilled, (state, { payload }) => {
      state.editingTag = payload;
      state.successfullyReceived = true;
      state.loading = false;
    });
    builder.addCase(getTagById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Get all Tags
    builder.addCase(getAllTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
      state.successfullyReceived = true;
      state.loading = false;
    });
    builder.addCase(getAllTags.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Delete Tag
    builder.addCase(deleteTag.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTag.fulfilled, (state) => {
      state.successfullyDeleted = true;
      state.loading = false;
    });
    builder.addCase(deleteTag.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Update Tag
    builder.addCase(updateTag.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTag.fulfilled, (state) => {
      state.successfullyUpdated = true;
      state.loading = false;
    });
    builder.addCase(updateTag.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
    // Create Tag
    builder.addCase(createTag.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTag.fulfilled, (state) => {
      state.successfullyCreated = true;
      state.loading = false;
    });
    builder.addCase(createTag.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
  },
});

export const { openTagsModal, closeTagsModal, resetTagState, setEditingTag } =
  tagsSlice.actions;

// Reducer
export default tagsSlice.reducer;
