import mixpanel from 'mixpanel-browser';

const isProd = process.env.REACT_APP_NODE_ENV === 'production';
// We have 20 million free mixpanel events per month, so leaving this enabled in dev/staging is fine for now
const mixpanelToken = isProd
  ? process.env.REACT_APP_MIXPANEL_PROD_TOKEN
  : process.env.REACT_APP_MIXPANEL_STAGING_TOKEN;

export enum MixpanelEventName {
  INVITE = 'Invite',
  REMOVE_AGENT = 'Remove Agent',
  LOAD = 'Load',
  SELECT_AGENT = 'Select Agent',
}

export const init = () => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken, { debug: !isProd });
  }
};

export const identify = (userId: number) => {
  if (mixpanelToken) {
    mixpanel.identify(userId);
  }
};

export const track = (event: MixpanelEventName, properties?: any) => {
  if (mixpanelToken) {
    mixpanel.track(event, properties);
  }
};
