import { createSlice } from '@reduxjs/toolkit';
import { getUser } from './actions';
import User from '../../interfaces/user';

interface UserState {
  loading: boolean;
  user: User | null;
}

const initialState: UserState = {
  loading: false,
  user: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loading = false;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default usersSlice.reducer;
