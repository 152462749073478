import { createSlice } from '@reduxjs/toolkit';
import { getMappedAgentData, getMappedAgentDataFromSignedUrl } from './actions';

interface MappedAgentDataState {
  hasErrors: boolean;
  loading: boolean;
  mappedAgentData: string[] | null;
}

const initialState: MappedAgentDataState = {
  hasErrors: false,
  loading: false,
  mappedAgentData: null,
};

const mappedAgentDataSlice = createSlice({
  name: 'mappedAgentData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMappedAgentData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMappedAgentData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.mappedAgentData = payload as any[];
    });
    builder.addCase(getMappedAgentData.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
    builder.addCase(getMappedAgentDataFromSignedUrl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getMappedAgentDataFromSignedUrl.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.hasErrors = false;
        state.mappedAgentData = payload as any[];
      },
    );
    builder.addCase(getMappedAgentDataFromSignedUrl.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
  },
});

export default mappedAgentDataSlice.reducer;
