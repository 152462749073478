/* eslint-disable prettier/prettier */
import { Auth } from '@aws-amplify/auth';

const cookieStorage = {
  domain:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? 'localhost'
      : 'listwithclever.com',
  secure: true,
  path: '/',
  expires: 365, // expires in days
  sameSite: 'none',
};
// DEBUGGING COGNITO STAGING
const userPoolId =
  process.env.REACT_APP_NODE_ENV === 'staging'
    ? process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID_STAGING
    : process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID;
const userPoolWebClientId =
  process.env.REACT_APP_NODE_ENV === 'staging'
    ? process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID_STAGING
    : process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID;
Auth.configure({
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
  userPoolId,
  userPoolWebClientId,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  cookieStorage,
});
