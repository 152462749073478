/* eslint-disable no-debugger */
import axios, { AxiosError } from 'axios';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { setErrorObject } from '../../store/globalErrors/reducer';
import { ErrorResource, ErrorShape } from '../../types/error';

interface HandleErrorObjectProps {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: AxiosError<unknown, any>;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  errorResource: ErrorResource;
}

export function handleErrorObject({
  error,
  dispatch,
  errorResource,
}: HandleErrorObjectProps) {
  const { response } = error;
  let message: string = String(response?.data) ?? 'Something went wrong';
  const code = error?.status || response?.status;
  // FIXME: when Backend error is unified we will not need to handle diff resources error types.
  // TODO handle diff resources error types
  if (errorResource === ErrorResource.CREATE_AGENT) {
    const errorShape = response?.data as ErrorShape[ErrorResource.CREATE_AGENT];
    const fieldName = Object.keys(errorShape)[0];
    const messageVal = errorShape[fieldName][0];
    message = `${fieldName}: ${messageVal}`;
  }
  // if BE error is an object, we will get object key value as message
  if (response?.data && typeof response?.data === 'object') {
    const arr = Object.entries(response?.data);
    message = `${arr[0][0]}: ${arr[0][1]}`;
  }
  console.error('error:', error);
  console.log('message:', message);
  console.log('code:', code);

  if (axios.isAxiosError(error)) {
    if (error.response?.headers['content-type'].includes('text/html')) {
      message = 'An unknown error has occurred.';
    }
  }

  dispatch(setErrorObject({ code, message, errorResource }));
  captureException(error);
}
