import { State } from '..';

export const selectAgentById = (state: State, id?: number) => {
  if (!id) {
    return null;
  }
  return state.agents.agentsMap[id];
};
export const selectAllAgents = (state: State) => state.agents.agentsMap;
export const agentsList = (state: State) => state.agents.selectedAgents;

export const isLoading = (state: State) =>
  state.agents.loading ||
  state.map.loading ||
  state.directMatches.loading ||
  state.invitations.loading ||
  state.searches.loading;

export const selectSelectedAgentId = (state: State) =>
  state.agents.selectedAgentId;
export const selectPendingAgentRequests = (state: State) =>
  state.agents.agentsCallingRequest;
