export const isDev = process.env.REACT_APP_NODE_ENV === 'development';
export const isProd = process.env.REACT_APP_NODE_ENV === 'production';

const fallback = {
  staging: {
    REACT_APP_API_URL: 'https://staging-api.listwithclever.com',
    REACT_APP_MAPBOX_ACCESS_TOKEN:
      'pk.eyJ1IjoicnlhbmNsZXZlciIsImEiOiJja2Q3c3E2azcwMnZoMnVwZHdmYTgxZWYyIn0.xt-whAZGlw2XVEUNhaVkfg',
  },
} as const;

export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const apiURL =
  process.env.REACT_APP_API_URL || fallback.staging.REACT_APP_API_URL;

export const mapboxToken =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  fallback.staging.REACT_APP_MAPBOX_ACCESS_TOKEN;

const AMP_Links = {
  development: 'http://localhost:3000/',
  staging: 'https://amp-staging.listwithclever.com/',
  test: 'https://amp-staging.listwithclever.com/',
  production: 'https://amp.listwithclever.com/',
} as const;

const loginLink = {
  development: 'http://localhost:3001/',
  staging: 'https://login-staging.listwithclever.com/',
  test: 'https://login-staging.listwithclever.com/',
  production: 'https://login.listwithclever.com/',
} as const;

const mapStyles = {
  dark: 'mapbox://styles/mapbox/dark-v10',
  light: 'mapbox://styles/mapbox/light-v8',
} as const;

/**
 * @description This is the configuration object for the application.
 * contains all the constants used in the application.
 *
 */
export const CONFIG = {
  isDev,
  isProd,
  baseURL,
  apiURL,
  mapboxToken,
  AMP_Links,
  loginLink,
  mapStyles,
  endpoints: {
    agents: {
      create: '/contacts/agents/',
    },
  },
  data: {
    demo: {
      agents: 'http://localhost:3000/data/agents.geojson',
      transactions: 'http://localhost:3000/data/transactions.geojson',
      mapped_agent_data: 'http://localhost:3000/data/mapped-agent-data.geojson',
      form_submissions: 'http://localhost:3000/data/form-submissions.geojson',
    },
    staging: {
      agents: `${apiURL}/deals/agents.geojson`,
      transactions: `${apiURL}/deals/transactions.geojson`,
      mapped_agent_data: `${apiURL}/deals/mapped-agent-data.geojson`,
      form_submissions: `${apiURL}/deals/form-submissions.geojson`,
    },
  },
};
