import { CONFIG, isDev } from '../config/constant';

/**
 * @description Handle Redirects to Clever login page.
 */
export function cleverLoginRedirect() {
  if (typeof window !== 'undefined') {
    window.location.href =
      CONFIG.loginLink[process.env.REACT_APP_NODE_ENV || 'development'];
  }
}

/**
 * @description Handle Redirects to Clever login page.
 * 1. Remove the user from local storage.
 * 2. Redirect to Clever login page. with cleverLoginRedirect()
 * @example
 * if (!isAuthenticated) return signOutHandler();
 */
export function signOutHandler() {
  if (typeof window !== 'undefined') {
    localStorage && localStorage.removeItem('me');
    cleverLoginRedirect();
  }
}
