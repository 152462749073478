/* eslint-disable no-shadow */
export enum AgentOrder {
  ID = '0',
  Eligible = '1',
  FormSubmission = '2',
  HighestSalePrice = '3',
  LowestSalePrice = '4',
  CommissionModels = '5',
  SignRate = '6',
  StatesAllowed = '7',
  Tag = '8',
  Distance = 'distance',
}
