import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AgentCreationSchemaFormValues } from '../../components/AgentCreation/utils/agentCreationSchema';
import { handleErrorObject } from '../../utils/errors/handleErrorObject';
import API from '../../services';
import { CreatedAgentBackendError } from '../../types/createAgent';
import { ErrorResource } from '../../types/error';

export const createAgent = createAsyncThunk(
  'agentCreation/createAgent',
  async (
    data: AgentCreationSchemaFormValues,
    { rejectWithValue, signal, dispatch },
  ) => {
    try {
      const response = await API.agentsService.create({
        data,
        config: {
          signal,
        },
      });
      return response.data;
    } catch (_err) {
      const error = _err as AxiosError;
      handleErrorObject({
        error,
        dispatch,
        errorResource: ErrorResource.CREATE_AGENT,
      });
      return rejectWithValue(error?.response?.data as CreatedAgentBackendError);
    }
  },
);
