import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ErrorResource } from '../../types/error';

type ErrorObject = {
  code?: number;
  message: string;
  errorResource: ErrorResource;
};

interface GlobalErrorState {
  errorObject: ErrorObject | null;
}

const initialState: GlobalErrorState = {
  errorObject: null,
};

const globalErrorsSlice = createSlice({
  name: 'globalErrors',
  initialState,
  reducers: {
    setErrorObject(
      state,
      action: PayloadAction<GlobalErrorState['errorObject']>,
    ) {
      state.errorObject = action.payload;
    },
  },
});

export const { setErrorObject } = globalErrorsSlice.actions;

export default globalErrorsSlice.reducer;
