import { createSlice } from '@reduxjs/toolkit';
import { Stats } from '../../interfaces/stats';
import { fetchAgentAreaStats } from './actions';

const initialState = {
  hasErrors: false,
  loading: false,
  timeframe: 1,
  displayGeoResults: false,
  referralType: 'both',
  polygon: {} as any,
  stats: {} as Stats,
};

const agentAreaStatsSlice = createSlice({
  name: 'agentAreaStats',
  initialState,
  reducers: {
    setTimeFrame(state, { payload }) {
      state.timeframe = payload;
    },
    setReferralType(state, { payload }) {
      state.referralType = payload;
    },
    displayGeoResults(state, { payload }) {
      state.displayGeoResults = payload;
      if (!payload) {
        // if hidden we reset the defaults
        state.referralType = 'both';
        state.timeframe = 1;
      }
    },
    setPolygon(state, { payload }) {
      state.polygon = payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Customer Deals
    builder.addCase(fetchAgentAreaStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAgentAreaStats.fulfilled, (state, { payload }) => {
      state.stats = payload;
      state.displayGeoResults = true;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchAgentAreaStats.rejected, (state) => {
      state.loading = false;
      state.hasErrors = true;
    });
  },
});

export const { setTimeFrame, setReferralType, displayGeoResults, setPolygon } =
  agentAreaStatsSlice.actions;
// Reducer
export default agentAreaStatsSlice.reducer;
