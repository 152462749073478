export enum TransactionOrder {
  AgentID = '0',
  AgentEligible = '1',
  FormSubmission = '2',
  Type = '3',
  SalePrice = '4',
  SaleDate = '5',
  ID = '6',
  AgentHighestSalePrice = '7',
  AgentLowestSalePrice = '8',
  CommissionModels = '9',
  SignRate = '10',
  Tags = '11',
  AgentHomebaseLngLat = '12',
  Distance = 'distance',
}
