import { CreatedAgentBackendError } from './createAgent';

export enum ErrorResource {
  CREATE_AGENT = 'CREATE_AGENT',
  DIRECT_MATCH_POST = 'DIRECT_MATCH_POST',
  MAP_GET_LOCATION_RESPONSE = 'MAP_GET_LOCATION_RESPONSE',
  MAP_RUN_ADDRESS_REQUEST = 'MAP_RUN_ADDRESS_REQUEST',
  MAP_GET_PRE_SIGNED_URLS = 'MAP_GET_PRE_SIGNED_URLS',
}

export type ErrorShape = {
  [ErrorResource.CREATE_AGENT]: CreatedAgentBackendError;
  // TODO: add more error shapes.
  [ErrorResource.DIRECT_MATCH_POST]: string;
  [ErrorResource.MAP_GET_LOCATION_RESPONSE]: string;
  [ErrorResource.MAP_RUN_ADDRESS_REQUEST]: string;
  [ErrorResource.MAP_GET_PRE_SIGNED_URLS]: string;
};
