import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../axiosInstance';
import { FeatureFlag } from '../../interfaces/FeatureFlag';

export const fetchFEOffersAgentEligibilityAMP = createAsyncThunk<
  FeatureFlag,
  void,
  { rejectValue: string }
>(
  'featureFlags/fetchFEOffersAgentEligibilityAMP',
  async (_, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        '/feature-flags/fe_offers_agent_eligibility_amp',
      );
      return response.data;
    } catch (error: any) {
      const val = error.message || error;
      return rejectWithValue(val);
    }
  },
);
