import { createSlice } from '@reduxjs/toolkit';
import { fetchAgentById, patchAgent } from './actions';
import Agent from '../../interfaces/agent';

interface AgentsStore {
  hasErrors: boolean;
  loading: boolean;
  agentsMap: { [key: string]: Agent };
  agentsCallingRequest: { [key: string]: 'pending' | 'fulfilled' | 'rejected' };
  selectedAgents: Agent[];
  selectedAgentId: string | null;
}

const initialState: AgentsStore = {
  hasErrors: false,
  loading: true,
  agentsMap: {},
  agentsCallingRequest: {},
  selectedAgents: [] as Agent[],
  selectedAgentId: null as string | null,
};

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    selectAgentById(state, action) {
      const selectedAgent = state.agentsMap[action.payload.id] as Agent;
      state.selectedAgents.push(selectedAgent);
    },
    setSelectedAgentId(state, action) {
      state.selectedAgentId = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Async agent fetch
    builder.addCase(fetchAgentById.pending, (state, { meta }) => {
      state.agentsCallingRequest[meta.arg] = 'pending';
    });
    builder.addCase(fetchAgentById.fulfilled, (state, { meta, payload }) => {
      state.agentsMap[meta.arg] = { ...payload, id: meta.arg };
      state.hasErrors = false;
    });
    builder.addCase(fetchAgentById.rejected, (state) => {
      state.hasErrors = true;
    });
    builder.addCase(patchAgent.pending, (state, { meta }) => {
      state.agentsCallingRequest[meta.arg.id] = 'pending';
    });
    builder.addCase(patchAgent.fulfilled, (state, { meta, payload }) => {
      state.agentsMap[meta.arg.id] = { ...payload, id: meta.arg.id };
      state.hasErrors = false;
    });
    builder.addCase(patchAgent.rejected, (state) => {
      state.hasErrors = true;
    });
  },
});

export const { selectAgentById, setSelectedAgentId, setIsLoading } =
  agentsSlice.actions;

// Reducer
export default agentsSlice.reducer;
