import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch,
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
  useSelector,
} from 'react-redux';
import { PreloadedState, combineReducers } from 'redux';
import configReducer from './config';
import agentReducer from './agents/reducer';
import searchReducer from './searches/reducer';
import searchFiltersReducer from './searchFilters/reducer';
import mapReducer from './map/reducer';
import modalReducer from './modal/reducer';
import invitationReducer from './invitations/reducer';
import directMatchesReducer from './directMatch/reducer';
import dealsReducer from './deals/reducer';
import nightModeReducer from './nightMode/reducer';
import mappedAgentDataReducer from './mappedAgentData/reducer';
import formSubmissionsReducer from './formSubmissions/reducer';
import pastSalesReducer from './pastSales/reducer';
import templateReducer from './templates/reducer';
import agentModalReducer from './agentModal/reducer';
import agentAreaStatsReducer from './agentAreaStats/reducer';
import agentNotesReducer from './agentNotes/reducer';
import marketNotesReducer from './marketNotes/reducer';
import tagsReducer from './tags/reducer';
import userReducer from './User/reducer';
import slaReducer from './slas/reducer';
import sourcingTerritoryReducer from './sourcingTerritories/reducer';
import outboundLiveTransferReducer from './outboundLiveTransfer/reducer';
import agentCreationSlice from './agentCreation/agentCreationSlice';
import globalErrorsSlice from './globalErrors/reducer';
import featureFlagsReducer from './featureFlags/reducer';

export const reducerList = {
  config: configReducer,
  templates: templateReducer,
  agentModal: agentModalReducer,
  agentCreation: agentCreationSlice,
  pastSales: pastSalesReducer,
  formSubmissions: formSubmissionsReducer,
  mappedAgentData: mappedAgentDataReducer,
  nightMode: nightModeReducer,
  deals: dealsReducer,
  agents: agentReducer,
  searches: searchReducer,
  searchFilters: searchFiltersReducer,
  map: mapReducer,
  modal: modalReducer,
  invitations: invitationReducer,
  directMatches: directMatchesReducer,
  agentAreaStats: agentAreaStatsReducer,
  agentNotes: agentNotesReducer,
  marketNotes: marketNotesReducer,
  tags: tagsReducer,
  users: userReducer,
  slas: slaReducer,
  sourcingTerritories: sourcingTerritoryReducer,
  outboundLiveTransfer: outboundLiveTransferReducer,
  globalErrors: globalErrorsSlice,
  featureFlags: featureFlagsReducer,
};

export const rootReducer = combineReducers({
  ...reducerList,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const setupStore = (preloadedState?: PreloadedState<State>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });

export type State = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
