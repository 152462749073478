import { createSlice } from '@reduxjs/toolkit';
import { ContextType } from '../../enums/ContextType';
import Agent from '../../interfaces/agent';

export interface NewBioContext {
  type: ContextType;
  name: string;
}

interface AgentModalState {
  agent: Agent | null;
  displayNewBioModal: boolean;
  bioModalContext: ContextType | null;
  newBio: NewBioContext | null;
}

const initialState: AgentModalState = {
  agent: null,
  displayNewBioModal: false,
  bioModalContext: null,
  newBio: null,
};

const agentModalSlice = createSlice({
  name: 'agentModal',
  initialState,
  reducers: {
    setCurrentAgent(state, action) {
      state.agent = action.payload;
    },
    removeCurrentAgent(state) {
      state.agent = null;
    },
    displayBioModal(state, action) {
      state.displayNewBioModal = true;
      state.bioModalContext = action.payload;
    },
    hideBioModal(state) {
      state.displayNewBioModal = false;
      state.bioModalContext = null;
    },
    setNewDefaultBio(state, action) {
      state.newBio = action.payload;
    },
  },
});

export const {
  setCurrentAgent,
  removeCurrentAgent,
  displayBioModal,
  hideBioModal,
  setNewDefaultBio,
} = agentModalSlice.actions;

export default agentModalSlice.reducer;
